import { Apartment } from 'types/Apartments';

export const parseApartmentsToFloors = (apartments: Apartment[]) => {
  const floors: any = {};

  apartments.map(apartment => {
    if (apartment.levels !== 1) {
      const aptFloors = [
        // @ts-ignore
        ...new Set(
          apartment.compartments.map(compartment => compartment.ref_tier),
        ),
      ];
      aptFloors.map(aptFloor => {
        floors[aptFloor] = floors[aptFloor] ? floors[aptFloor] + 1 : 1;
      });
    } else {
      const idx = apartment.compartments[0].ref_tier;
      floors[idx] = floors[idx] ? floors[idx] + 1 : 1;
    }
  });

  return floors;
};
