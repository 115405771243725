import axios from 'axios';

import { config } from 'config';
import { ApartmentsResponse } from 'types/Apartments';

export const getApartmentsData =
  async (): Promise<ApartmentsResponse | null> => {
    try {
      const url = `${config.strapiUrl}/api/apartments`;

      const response = await axios.get<ApartmentsResponse>(url, {
        params: {
          populate: 'Compartments',
        },
      });
      return response.data;
    } catch (err) {
      console.error('[Strapi API failed]', err);
      return null;
    }
  };
