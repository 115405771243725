import { Splide, SplideSlide } from '@splidejs/react-splide';
// eslint-disable-next-line import/no-unresolved
import '@splidejs/react-splide/css';
import { useState } from 'react';

import ImageExpand from 'components/ImageExpand';

import styles from './styles.module.scss';
import { Props } from './types';

const Carousel = ({ imagesUrls }: Props) => {
  const [expandedImage, setExpandedImage] = useState<string | undefined>(
    undefined,
  );

  const renderImages = (forSplide: boolean) => {
    const carouselImages: any[] = [];

    imagesUrls.map(imageUrl => {
      forSplide
        ? carouselImages.push(
            <SplideSlide className={styles.singleslide}>
              <img
                src={imageUrl}
                className={styles.image}
                onClick={() => setExpandedImage(imageUrl)}
              />
            </SplideSlide>,
          )
        : carouselImages.push(
            <img
              src={imageUrl}
              className={styles.image}
              onClick={() => setExpandedImage(imageUrl)}
            />,
          );
    });

    return carouselImages;
  };

  return (
    <div className={styles.container}>
      <Splide
        options={{
          type: 'loop',
          perPage: 1,
          rewind: true,
          gap: '1rem',
          width: '100%',
          autoplay: true,
          speed: 800,
          interval: 3000,
          lazyLoad: 'nearby',
        }}
      >
        {renderImages(true)};
      </Splide>
      {expandedImage && (
        <ImageExpand
          expandedImage={expandedImage}
          onRemoveExpand={() => setExpandedImage(undefined)}
          images={imagesUrls}
        >
          {renderImages(false)}
        </ImageExpand>
      )}
    </div>
  );
};

export default Carousel;
