import axios from 'axios';

import { config } from 'config';
import { ContactResponse } from 'types/Contact';

export const getContactData = async (): Promise<ContactResponse | null> => {
  try {
    const url = `${config.strapiUrl}/api/contact-config`;
    const response = await axios.get<ContactResponse>(url);
    return response.data;
  } catch (err) {
    console.error('[Strapi API failed]', err);
    return null;
  }
};
