import { Row } from '@tanstack/react-table';

import { Compartment } from 'types/Apartments';

export const kitchenFilter = (
  row: Row<any>,
  columnId: string,
  filterValue: any,
): boolean => {
  const { compartments } = row.original;

  // no info about compartments - hide record.
  if (!compartments) return false;

  const hasKitchenCompartment = () =>
    compartments.some((x: Compartment) => x.name === 'id.kuchnia');

  if (filterValue === 'kuchniaJakoOsobne') return hasKitchenCompartment();
  if (filterValue === 'aneksKuchenny') return !hasKitchenCompartment();

  return true;
};
