import styles from './styles.module.scss';
import { Props } from './types';

// bgColor is TEMP - remove
const Section2 = ({
  id,
  label,
  sublabel,
  isInverted,
  children1,
  children2,
  bgColor,
}: Props) => {
  return (
    <div
      id={id}
      className={styles.container}
      style={{ backgroundColor: bgColor || 'white' }}
    >
      <div className={[styles.wrapper, styles.contentSection].join(' ')}>
        <h2 className={styles.header}>
          <b>{label}</b> {sublabel}
        </h2>
        <div className={styles.contentWrapper}>{children2}</div>
      </div>
      <div className={styles.wrapper}>{children1}</div>
    </div>
  );
};

export default Section2;
