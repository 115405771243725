import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { createUseStyles } from 'react-jss';

import { sendEmail } from 'api/sendEmail.api';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import { ContactModalContext } from 'context/contactModalContext';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import { CustomTheme } from 'types/Style';
import { ContactSchema } from 'utils/validation';

import styles from './styles.module.scss';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  checkedTermsCheckbox: {
    backgroundColor: theme.primary,
  },
  termsError: {
    color: theme.primary,
  },
  callBtn: {
    '&:hover': {
      color: theme.primary,
    },
  },
  sendMessage: {
    color: theme.primary,
  },
}));

const ContactSection = () => {
  const { t } = useContext(TranslationContext);
  const { mailUrl, mailEndpoint, mailRecipient } =
    useContext(ContactModalContext);
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });
  const [sendMessage, setSendMessage] = useState<string>('');
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const handleSubmit = () => {
    if (submitDisabled) return;

    const { name, email, phone, message } = formik.values;
    const queryParams = new URLSearchParams(window.location.search);

    const utmCampaign = queryParams.get('utm_campaign') || '';
    const utmSource = queryParams.get('utm_source') || '';
    const utmMedium = queryParams.get('utm_medium') || '';
    const utmTerm = queryParams.get('utm_term') || '';
    const utmContent = queryParams.get('utm_content') || '';

    sendEmail(
      name,
      email,
      phone,
      message,
      utmCampaign,
      utmSource,
      utmMedium,
      utmTerm,
      utmContent,
      mailUrl,
      mailEndpoint,
      mailRecipient,
    )
      .then(() => {
        setSendMessage(t('sendMessageSuccess'));
        setSubmitDisabled(true);
        formik.resetForm();
      })
      .catch(() => setSendMessage(t('sendMessageError')));
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
      termsAccepted: false,
      marketingTermsAccepted: false,
    },
    onSubmit: () => handleSubmit(),
    validationSchema: ContactSchema(t),
  });

  const changeAcceptTermsValue = () => {
    formik.setFieldValue('termsAccepted', !formik.values.termsAccepted);
  };

  const changeAcceptMarketingTermsValue = () => {
    formik.setFieldValue(
      'marketingTermsAccepted',
      !formik.values.marketingTermsAccepted,
    );
  };

  return (
    <div id="contact" className={styles.container}>
      <div className={styles.contact}>
        <div className={styles.contactDetails}>
          <h2 className={styles.header}>{t('contact')}</h2>
        </div>
        <div className={styles.contentWrapper}>
          <span className={styles.claim}>{t('contactClaim')}</span>
          <div className={styles.contactDetails}>
            <div className={styles.otherContact}>
              {t('orCall')}
              <div>
                <a
                  href={`tel: +48501469987`}
                  className={[classes.callBtn, styles.callBtn].join(' ')}
                >
                  +48 501 469 987
                </a>
              </div>
            </div>
            <div className={styles.otherContact}>
              {t('orSendEmail')}
              <div>
                <a
                  href="mailto: aplus@cadaplus.com"
                  className={[classes.callBtn, styles.callBtn].join(' ')}
                >
                  aplus@cadaplus.com
                </a>
              </div>
            </div>
          </div>
          <div className={styles.contactForm}>
            <div className={styles.firstRow}>
              <Input
                label={t('contactName')}
                value={formik.values.name}
                placeholder={isMobile ? t('contactName') : t('yourName')}
                onChange={formik.handleChange('name')}
                error={formik.touched.name ? formik.errors.name : ''}
              />
              <Input
                label={t('mail')}
                value={formik.values.email}
                placeholder={
                  isMobile ? t('eMailAddress') : t('yourEMailAddress')
                }
                onChange={formik.handleChange('email')}
                error={formik.touched.email ? formik.errors.email : ''}
              />
              <Input
                label={t('phone')}
                value={formik.values.phone}
                placeholder={isMobile ? t('phoneNumber') : t('yourPhoneNumber')}
                onChange={formik.handleChange('phone')}
                error={formik.touched.phone ? formik.errors.phone : ''}
              />
            </div>
            <TextArea
              label={t('message')}
              value={formik.values.message}
              placeholder={t('yourMessage')}
              onChange={formik.handleChange('message')}
              error={formik.touched.message ? formik.errors.message : ''}
            />
            <div className={styles.termsWrapper}>
              <div
                className={styles.termsCheckboxContainer}
                onClick={changeAcceptTermsValue}
              >
                {formik.values.termsAccepted ? (
                  <div
                    className={[
                      classes.checkedTermsCheckbox,
                      styles.checkedTermsCheckbox,
                    ].join(' ')}
                  />
                ) : null}
              </div>
              <span className={styles.label}>{t('acceptTerms')}</span>
            </div>
            {formik.touched.termsAccepted && (
              <span
                className={[classes.termsError, styles.termsError].join(' ')}
              >
                {formik.errors.termsAccepted}
              </span>
            )}
            <div className={styles.termsWrapper}>
              <div
                className={styles.termsCheckboxContainer}
                onClick={changeAcceptMarketingTermsValue}
              >
                {formik.values.marketingTermsAccepted ? (
                  <div
                    className={[
                      classes.checkedTermsCheckbox,
                      styles.checkedTermsCheckbox,
                    ].join(' ')}
                  />
                ) : null}
              </div>
              <span className={styles.label}>{t('acceptMarketingTerms')}</span>
            </div>
            {formik.touched.marketingTermsAccepted && (
              <span
                className={[classes.termsError, styles.termsError].join(' ')}
              >
                {formik.errors.marketingTermsAccepted}
              </span>
            )}
            <div className={styles.contactActions}>
              <div>
                {/* @ts-ignore */}
                <button className={styles.btn} onClick={formik.handleSubmit}>
                  {t('askForOffer')}
                </button>
              </div>
            </div>
            {sendMessage && (
              <span
                className={[classes.sendMessage, styles.sendMessage].join(' ')}
              >
                {sendMessage}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className={styles.map}>
        <div>
          <iframe
            width="100%"
            height="600"
            frameBorder="0"
            scrolling="no"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=pl&amp;q=Kazimierzowska%2049+(BIM+)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed&language=pl"
          >
            <a href="https://www.maps.ie/distance-area-calculator.html">
              area maps
            </a>
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
