import { useContext, useState } from 'react';

import ShowMore from 'components/ShowMore';
import { TranslationContext } from 'context/translationContext';

const InvestmentAnalyzerDescription = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useContext(TranslationContext);

  const toggleOpen = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div>
      <span>{t('investmentAnalyzerClaim')}</span>
      <br />
      <br />
      <ul>
        <li>{t('investmentAnalyzerAdvantage1')}</li>
        <li>
          {t('investmentAnalyzerAdvantage2')}
          <ul>
            <li> {t('investmentAnalyzerAdvantage21')}</li>
            <li> {t('investmentAnalyzerAdvantage22')}</li>
          </ul>
        </li>
        <li>{t('investmentAnalyzerAdvantage3')}</li>
        <li>{t('investmentAnalyzerAdvantage4')}</li>
        <li>{t('investmentAnalyzerAdvantage5')}</li>
      </ul>
      {isOpen && (
        <>
          <br />
          <p>{t('investmentAnalyzerDescription1')}</p>
          <br />
        </>
      )}
      <ShowMore isOpen={isOpen} handleClick={toggleOpen} />
    </div>
  );
};

export default InvestmentAnalyzerDescription;
