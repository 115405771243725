import { FlatPlacementValues } from 'constants/options';

import styles from './styles.module.scss';
import { Props } from './types';

const FlatPlacementSelect = ({ name, values, options, onChange }: Props) => {
  const disableAllOthers = (value: string, leftActive: string) => {
    return (
      values.findIndex(val => val === leftActive) !== -1 && value !== leftActive
    );
  };

  const disableTwoOthers = (
    value: string,
    leftActive: string,
    firstToDisable: string,
    secondToDisable: string,
  ) => {
    return (
      values.findIndex(val => val === leftActive) !== -1 &&
      (value === firstToDisable || value === secondToDisable)
    );
  };

  const checkIfActive = (value: string) => {
    if (
      disableAllOthers(value, FlatPlacementValues.tylkoParter) ||
      disableAllOthers(value, FlatPlacementValues.tylkoOstatniePietro) ||
      disableTwoOthers(
        value,
        FlatPlacementValues.bezOstatniegoPietra,
        FlatPlacementValues.tylkoParter,
        FlatPlacementValues.tylkoOstatniePietro,
      ) ||
      disableTwoOthers(
        value,
        FlatPlacementValues.bezParteru,
        FlatPlacementValues.tylkoParter,
        FlatPlacementValues.tylkoOstatniePietro,
      )
    ) {
      return true;
    }

    return false;
  };

  return (
    <div>
      {options.map(option => {
        const isDisabled = checkIfActive(option.value);

        return (
          <label
            key={option.value}
            className={[styles.container, isDisabled && styles.disabled].join(
              ' ',
            )}
          >
            <input
              type="checkbox"
              name={name}
              onChange={onChange}
              value={option.value}
              className={styles.input}
              checked={values.findIndex(val => val === option.value) !== -1}
              disabled={isDisabled}
            />
            <span>{option.label}</span>
          </label>
        );
      })}
    </div>
  );
};

export default FlatPlacementSelect;
