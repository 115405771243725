import { Row } from '@tanstack/react-table';

import { Compartment } from 'types/Apartments';

export const flatPlacementFilter = (
  row: Row<any>,
  columnId: string,
  filterValue: string[],
  maxTier: number,
): boolean => {
  const { compartments } = row.original;

  // filter empty
  if (!filterValue.length) return true;

  // no info about floor - hide record.
  if (!compartments) return false;

  const floors: number[] = Array.from(
    new Set<number>(compartments.map((x: Compartment) => x.ref_tier)),
  );

  // only top floor
  if (filterValue.includes('tylkoOstatniePietro')) {
    // floors have to have only 4
    return floors.includes(maxTier) && floors.length === 1;
  }

  // only 0 floor
  if (filterValue.includes('tylkoParter')) {
    return floors.includes(0) && floors.length === 1;
  }

  // no 0 and top floors
  if (
    filterValue.includes('bezOstatniegoPietra') &&
    filterValue.includes('bezParteru')
  ) {
    return !floors.includes(0) && !floors.includes(maxTier);
  }

  // no top floor
  if (filterValue.includes('bezOstatniegoPietra')) {
    return !floors.includes(maxTier);
  }

  // no 0 floor
  if (filterValue.includes('bezParteru')) {
    return !floors.includes(0);
  }

  return false;
};
