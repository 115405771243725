import Carousel from 'components/Carousel';

import img1 from '../../assets/images/bim-1-min.jpg';
import img2 from '../../assets/images/bim-2-min.jpg';
import img3 from '../../assets/images/bim-3-min.jpg';
import img4 from '../../assets/images/bim-4-min.jpg';
import img5 from '../../assets/images/bim-5-min.jpg';
import img6 from '../../assets/images/bim-6-min.jpg';

const InvestmentAnalyzerGallery = () => {
  const investmentAnalyzerImages = [img1, img2, img3, img4, img5, img6];

  return (
    <div>
      <Carousel imagesUrls={investmentAnalyzerImages} />
    </div>
  );
};

export default InvestmentAnalyzerGallery;
