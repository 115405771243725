import { XIcon } from '@heroicons/react/outline';
import { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { Carousel } from 'react-responsive-carousel';

import GalleryArrow from 'components/GalleryArrow';

import styles from './styles.module.scss';
import { Props } from './types';

const ImageExpand = ({
  expandedImage,
  onRemoveExpand,
  images,
  children,
}: Props) => {
  const escFunction = useCallback((e: any) => {
    if (e.key === 'Escape') {
      onRemoveExpand();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const selectedItem = useMemo(() => {
    return images.findIndex(img => img === expandedImage);
  }, [expandedImage]);

  const handleChildClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div
      className={[styles.container, 'imageExpand'].join(' ')}
      onClick={onRemoveExpand}
    >
      <div
        className={[styles.wrapper, 'expandImg'].join(' ')}
        onClick={handleChildClick}
      >
        <Carousel
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          swipeable={false}
          selectedItem={selectedItem}
          renderArrowPrev={(clickHandler, hasPrev) => (
            <GalleryArrow
              clickHandler={clickHandler}
              hasPrev={hasPrev}
              dir="left"
              isInExpand
              isWide={!isMobile}
            />
          )}
          renderArrowNext={(clickHandler, hasPrev) => (
            <GalleryArrow
              clickHandler={clickHandler}
              hasPrev={hasPrev}
              dir="right"
              isInExpand
              isWide={!isMobile}
            />
          )}
        >
          {children}
        </Carousel>
      </div>
      <div className={styles.closeBtnWrapper} onClick={onRemoveExpand}>
        <XIcon className={styles.closeBtn} />
      </div>
    </div>
  );
};

export default ImageExpand;
