import { Row } from '@tanstack/react-table';

export const directionsFilter = (
  row: Row<any>,
  columnId: string,
  filterValue: any,
): boolean => {
  const { exposition } = row.original;

  // filter emtpy
  if (!exposition) return true;

  // TODO: filter by exposition
  return true;
};
