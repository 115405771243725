import { useContext, useState } from 'react';
import { GoPlus } from 'react-icons/go';

import { TranslationContext } from 'context/translationContext';
import { scrollToSection } from 'utils/scrollToSection';

import logo from '../../assets/images/bim.svg';

import styles from './styles.module.scss';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { t } = useContext(TranslationContext);

  return (
    <section className={styles.container}>
      <div className={styles.logo}>
        <a
          onClick={() => {
            scrollToSection('start');
          }}
        >
          <img src={logo} alt="BIM" />
        </a>
      </div>
      <div
        className={[
          styles.mobileMenuOverlay,
          isMobileMenuOpen && styles.visible,
        ].join(' ')}
        onClick={() => setIsMobileMenuOpen(false)}
      />
      <div
        className={[styles.menu, isMobileMenuOpen && styles.opened].join(' ')}
      >
        <div
          className={styles.menuClose}
          onClick={() => setIsMobileMenuOpen(false)}
        />
        <div className={styles.dropdown}>
          <div
            className={styles.dropbtn}
            onClick={() => {
              scrollToSection('apartmentSection');
              setIsMobileMenuOpen(false);
            }}
          >
            {t('graphicElements')}
            <GoPlus />
          </div>
          <div className={styles.dropdowncontent}>
            <a
              onClick={() => {
                scrollToSection('gallerySection');
                setIsMobileMenuOpen(false);
              }}
            >
              {t('galleryMenu')}
            </a>
            <a
              onClick={() => {
                scrollToSection('floorsSection');
                setIsMobileMenuOpen(false);
              }}
            >
              {t('floorsMenu')}
            </a>
            <a
              onClick={() => {
                scrollToSection('apartmentSection');
                setIsMobileMenuOpen(false);
              }}
            >
              {t('catalogMenu')}
            </a>
            <a
              onClick={() => {
                scrollToSection('mapSection');
                setIsMobileMenuOpen(false);
              }}
            >
              {t('mapsMenu')}
            </a>
          </div>
        </div>
        <div className={styles.dropdown}>
          <div
            className={styles.dropbtn}
            onClick={() => {
              scrollToSection('threeDSection');
              setIsMobileMenuOpen(false);
            }}
          >
            {t('wwwComponents')}
            <GoPlus />
          </div>
          <div className={styles.dropdowncontent}>
            <a
              onClick={() => {
                scrollToSection('threeDSection');
                setIsMobileMenuOpen(false);
              }}
            >
              {t('3dMockupMenu')}
            </a>
            <a
              onClick={() => {
                scrollToSection('vtripSection');
                setIsMobileMenuOpen(false);
              }}
            >
              {t('3dlocal')}
            </a>
            <a
              onClick={() => {
                scrollToSection('tableSection');
                setIsMobileMenuOpen(false);
              }}
            >
              {t('searchEngineMenu')}
            </a>
            <a
              onClick={() => {
                scrollToSection('pagesSection');
                setIsMobileMenuOpen(false);
              }}
            >
              {t('wwwWebsites')}
            </a>
          </div>
        </div>
        <div className={styles.dropdown}>
          <div
            className={styles.dropbtn}
            onClick={() => {
              scrollToSection('cartSection');
              setIsMobileMenuOpen(false);
            }}
          >
            {t('extraServices')}
            <GoPlus />
          </div>
          <div className={styles.dropdowncontent}>
            <a
              onClick={() => {
                scrollToSection('cartSection');
                setIsMobileMenuOpen(false);
              }}
            >
              {t('cardMenu')}
            </a>
            <a
              onClick={() => {
                scrollToSection('visualisationSection');
                setIsMobileMenuOpen(false);
              }}
            >
              {t('visualizationsMenu')}
            </a>
            <a
              onClick={() => {
                scrollToSection('analyzerSection');
                setIsMobileMenuOpen(false);
              }}
            >
              {t('investmentAnalyzerMenu')}
            </a>
            <a
              onClick={() => {
                scrollToSection('graphicsSection');
                setIsMobileMenuOpen(false);
              }}
            >
              {t('graphicsMenu')}
            </a>
            <a
              onClick={() => {
                scrollToSection('scan3DSection');
                setIsMobileMenuOpen(false);
              }}
            >
              {t('scan3DMenu')}
            </a>
          </div>
        </div>
        <div>
          <div
            className={styles.dropbtn}
            onClick={() => {
              scrollToSection('contact');
              setIsMobileMenuOpen(false);
            }}
          >
            {t('contact')}
          </div>
        </div>
      </div>
      <div
        className={styles.hamburger}
        onClick={() => setIsMobileMenuOpen(true)}
      >
        <a href="#">
          <svg
            version="1.1"
            id="menu-hamburger"
            viewBox="0 0 16 16"
            x="0px"
            y="0px"
            xlinkHref="#menu-hamburger"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <rect y="1" width="16" height="2"></rect>
              <rect y="7" width="16" height="2"></rect>
              <rect y="13" width="16" height="2"></rect>
            </g>
          </svg>
        </a>
      </div>
    </section>
  );
};

export default Header;
