import { saveAs } from 'file-saver';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { ReactSVG } from 'react-svg';

import CompassRose from 'assets/icons/north.svg';
import Pdf from 'assets/icons/pdf-icon.png';
import ApartmentDetails from 'components/ApartmentDetails';
import ApartmentLegend from 'components/ApartmentLegend';
import Loader from 'components/Loader';
import RoomDetails from 'components/RoomDetails';
import RoomsTable from 'components/RoomsTable';
import { ContactModalContext } from 'context/contactModalContext';
import { DataContext } from 'context/dataContext';
import { NavigationContext } from 'context/navigationContext';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import { Apartment, Compartment } from 'types/Apartments';
import { CustomTheme } from 'types/Style';

import styles from './styles.module.scss';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  container: {
    backgroundColor: theme.primary,
  },
  image: {
    border: `1px solid ${theme.primary}`,
  },
  apartmentDescription: {
    '&:before': {
      borderBottom: `1px solid ${theme.primary}`,
    },
  },
}));

const ApartmentView = () => {
  const { apartments, apartmentsImages } = useContext(DataContext);
  const { selectedAptId } = useContext(NavigationContext);
  const { openModal } = useContext(ContactModalContext);
  const { t } = useContext(TranslationContext);
  const [apartment, setApartment] = useState<Apartment | null>(apartments[0]);
  const [selectedRoom, setSelectedRoom] = useState<Compartment | null>(null);
  const [rooms, setRooms] = useState<Compartment[]>(apartments[0].compartments);
  const [tableHover, setTableHover] = useState('');
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });

  useEffect(() => {
    if (selectedAptId) {
      const foundApt = apartments.find(apt => apt.id === selectedAptId);
      if (foundApt) {
        setApartment(foundApt);
        setRooms(foundApt.compartments);
      }
    }
  }, [selectedAptId]);

  const handleClick = (e: any) => {
    if (e.target.nodeName === 'path' && e.target.attributes.ind) {
      const roomIndex = parseInt(e.target.attributes.ind.value);
      const room = rooms[roomIndex];
      room.id = roomIndex.toString();

      if (room) {
        setSelectedRoom(prevRoom => {
          if (prevRoom === room) {
            return null;
          }
          return room;
        });
      } else {
        setSelectedRoom(null);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const height = document.getElementById('root')?.scrollHeight;
      window.parent.postMessage(['setHeight', height], '*');
    }, 100);
  }, [selectedRoom]);

  useEffect(() => {
    const paths = document.querySelectorAll('path.rm');
    paths.forEach((path: Element) => {
      const index = path.getAttribute('ind');
      if (index === selectedRoom?.id) {
        path.classList.add('selected');
      } else {
        path.classList.remove('selected');
      }
    });
  }, [selectedRoom]);

  const addTableHover = useCallback((e: any) => {
    const roomIndex = parseInt(e.target.attributes.ind.value);
    setTableHover(roomIndex.toString());
  }, []);

  const removeTableHover = () => {
    setTableHover('');
  };

  useEffect(() => {
    return () => {
      const paths = document.querySelectorAll('#apartmentLayout path.rm');

      for (let i = 0; i < paths.length; i++) {
        paths[i].removeEventListener('mouseenter', addTableHover);
        paths[i].removeEventListener('mouseleave', removeTableHover);
      }
    };
  }, []);

  const addListeners = () => {
    const paths = document.querySelectorAll('#apartmentLayout path.rm');

    for (let i = 0; i < paths.length; i++) {
      paths[i].addEventListener('mouseenter', addTableHover);
      paths[i].addEventListener('mouseleave', removeTableHover);
    }
  };

  const handleAfterInjection = useCallback(() => {
    const compartments = document.getElementsByClassName('rm');
    for (let i = 0; i < compartments.length; i++) {
      const idx = compartments[i].getAttribute('ind');

      if (idx) {
        const compartment = rooms[parseInt(idx)];

        if (compartment) {
          compartments[i].classList.add(compartment.name.split('.')[1]);
        }
      }
    }
    addListeners();
  }, [rooms]);

  const handleAskForPriceClick = () => {
    const offerNumber = apartment?.number;
    openModal(`${t('interestedIn')} ${offerNumber}, ${t('presentOffer')}`);
  };

  const fetchAprtCard = () => {
    if (apartment) {
      const fileName = `${apartment.number}.pdf`;
      saveAs(`aprtCards/${fileName}`, fileName);
    }
  };

  const renderAptImage = useMemo(() => {
    if (apartment && apartmentsImages.length !== 0) {
      const imgUrl = apartmentsImages.find(url => url.includes(apartment.id));

      if (imgUrl) {
        return (
          <ReactSVG
            id="apartmentLayout"
            src={imgUrl}
            className={[classes.image, styles.image].join(' ')}
            wrapper="svg"
            renumerateIRIElements={false}
            onClick={handleClick}
            afterInjection={handleAfterInjection}
          />
        );
      }
    }

    return <Loader />;
  }, [apartment, apartmentsImages]);

  return (
    <>
      <div className={[classes.container, styles.container].join(' ')}>
        <div className={styles.leftCol}>
          {renderAptImage}
          <ApartmentLegend />
          <img
            src={CompassRose}
            className={styles.compassRose}
            style={{
              transform: `rotate(${apartment?.azimuth}deg)`,
            }}
          />
        </div>
        <div className={styles.rightCol}>
          {apartment ? (
            <>
              <div className={styles.tableWrapper}>
                <RoomsTable
                  data={rooms}
                  selectedRoom={selectedRoom}
                  setSelectedRoom={setSelectedRoom}
                  tableHover={tableHover}
                />
              </div>
              <div
                className={[
                  classes.apartmentDescription,
                  styles.apartmentDescription,
                ].join(' ')}
              >
                {selectedRoom && <RoomDetails room={selectedRoom} />}
                <ApartmentDetails apartment={apartment} />
                <div className={styles.buttonsContainer}>
                  <div className={styles.buttonWrapper}>
                    <button onClick={fetchAprtCard} className={styles.cardBtn}>
                      {t('aprtCard')}
                      <img src={Pdf} className={styles.icon} />
                    </button>
                  </div>
                  <div className={styles.buttonWrapper}>
                    <button onClick={handleAskForPriceClick}>
                      {t('askForOffer')}
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};

export default ApartmentView;
