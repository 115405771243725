import project from 'data/project.json';

export const config = {
  locale: project._languages[0] || 'pl',
  investmentName: process.env.REACT_APP_INVESTMENT_NAME,
  backendUrl: process.env.REACT_APP_BACKEND_URL,
  phone: process.env.REACT_APP_INVESTMENT_TEL,
  strapiUrl:
    process.env.REACT_APP_STRAPI_URL +
    '/' +
    process.env.REACT_APP_INVESTMENT_NAME,
};
