import Carousel from 'components/Carousel';

import img1 from '../../assets/images/wiz-1.jpg';
import img2 from '../../assets/images/wiz-2.jpg';

const VisualizationGallery = () => {
  const visualizationImages = [img1, img2];

  return (
    <div>
      <Carousel imagesUrls={visualizationImages} />
    </div>
  );
};

export default VisualizationGallery;
