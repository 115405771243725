import { useEffect, useCallback } from 'react';
import { isMobile } from 'react-device-detect';

import styles from './styles.module.scss';
import { Props } from './types';

const Tooltip = ({ id, isOpen, children }: Props) => {
  const move = useCallback((e: MouseEvent) => {
    const tooltip = document.getElementById(id);
    const x = e.pageX;
    const y = e.pageY;

    if (tooltip) {
      tooltip.style.left = x + 20 + 'px';
      tooltip.style.top = y + 5 + 'px';
    }
  }, []);

  const addListener = () => {
    document.addEventListener('mousemove', move);
  };

  const removeListener = () => {
    document.removeEventListener('mousemove', move);
  };

  useEffect(() => {
    if (!isMobile) {
      return () => removeListener();
    }
  }, []);

  useEffect(() => {
    if (!isMobile) {
      const tooltip = document.getElementById(id);
      if (isOpen) {
        addListener();
        if (tooltip) {
          tooltip.style.display = 'block';
        }
      } else {
        removeListener();
        if (tooltip) {
          tooltip.style.display = 'none';
        }
      }
    }
  }, [isOpen]);

  return (
    <div id={id} className={styles.tooltip}>
      {children}
    </div>
  );
};

export default Tooltip;
