import { ChevronDownIcon } from '@heroicons/react/outline';
import { useState } from 'react';

import styles from './styles.module.scss';
import { Props } from './types';

const DropdownModalItem = ({ label, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div
      className={[styles.container, isOpen && styles.openContainer].join(' ')}
    >
      <div className={styles.wrapper} onClick={toggleDropdown}>
        <p className={styles.label}>{label}</p>
        <ChevronDownIcon
          className={[styles.icon, isOpen && styles.rotate].join(' ')}
        />
      </div>
      {isOpen && children}
    </div>
  );
};

export default DropdownModalItem;
