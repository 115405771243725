import { createContext, ReactNode, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';

import { getStyleData } from 'api/style.api';
import { ListType } from 'types/Style';

export interface StyleContext {
  isMapHidden: boolean;
  listType: ListType;
  primaryColor: string;
  primaryColorDark: string;
  roomAmountColors: RoomTheme;
  compartmentsColors: CompartmentsTheme;
}

interface StyleContextProps {
  children: ReactNode;
}

export interface RoomTheme {
  room1: string;
  room2: string;
  room3: string;
  room4: string;
  room5OrMore: string;
}

export interface CompartmentsTheme {
  livingRoom: string;
  bathroom: string;
  kitchen: string;
  bedroom: string;
  terrace: string;
  wc: string;
}

const useStyles = createUseStyles((theme: RoomTheme & CompartmentsTheme) => ({
  '@global': {
    body: {
      '& .apartmentsWithOverlay': {
        '& .room-1': {
          fill: `${theme.room1} !important`,
          stroke: `${theme.room1} !important`,
        },
        '& .room-2': {
          fill: `${theme.room2} !important`,
          stroke: `${theme.room2} !important`,
        },
        '& .room-3': {
          fill: `${theme.room3} !important`,
          stroke: `${theme.room3} !important`,
        },
        '& .room-4': {
          fill: `${theme.room4} !important`,
          stroke: `${theme.room4} !important`,
        },
        '& .room-5': {
          fill: `${theme.room5OrMore} !important`,
          stroke: `${theme.room5OrMore} !important`,
        },
      },
      '& #apartmentLayout': {
        '& .salon': {
          fill: theme.livingRoom,
        },
        '& .lazienka': {
          fill: theme.bathroom,
        },
        '& .kuchnia': {
          fill: theme.kitchen,
        },
        '& .sypialnia': {
          fill: theme.bedroom,
        },
        '& .taras': {
          fill: theme.terrace,
        },
        '& .wc': {
          fill: theme.wc,
        },
      },
      '& #tooltipApartmentLayout': {
        '& .salon': {
          fill: theme.livingRoom,
        },
        '& .lazienka': {
          fill: theme.bathroom,
        },
        '& .kuchnia': {
          fill: theme.kitchen,
        },
        '& .sypialnia': {
          fill: theme.bedroom,
        },
        '& .taras': {
          fill: theme.terrace,
        },
        '& .wc': {
          fill: theme.wc,
        },
      },
      '& .tileApartmentLayout': {
        '& .salon': {
          fill: theme.livingRoom,
        },
        '& .lazienka': {
          fill: theme.bathroom,
        },
        '& .kuchnia': {
          fill: theme.kitchen,
        },
        '& .sypialnia': {
          fill: theme.bedroom,
        },
        '& .taras': {
          fill: theme.terrace,
        },
        '& .wc': {
          fill: theme.wc,
        },
      },
    },
  },
}));

export const StyleContext = createContext<StyleContext>({
  isMapHidden: false,
  listType: 'tabela',
  primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#8ba638',
  primaryColorDark: process.env.REACT_APP_SECONDARY_COLOR || '#823185',
  roomAmountColors: {
    room1: '#4D6AA8',
    room2: '#7FA25A',
    room3: '#B8A136',
    room4: '#D46C40',
    room5OrMore: '#AC40D4',
  },
  compartmentsColors: {
    livingRoom: '#4D6AA8',
    bathroom: '#7FA25A',
    kitchen: '#D46C40',
    bedroom: '#B8A136',
    terrace: '#c2b280',
    wc: '#fc6868',
  },
});

export const StyleProvider = ({ children }: StyleContextProps) => {
  const [isMapHidden, setIsMapHidden] = useState(false);
  const [listType, setListType] = useState<ListType>('tabela');
  const [primaryColor, setPrimaryColor] = useState(
    process.env.REACT_APP_PRIMARY_COLOR || '#8ba638',
  );
  const [primaryColorDark, setPrimaryColorDark] = useState(
    process.env.REACT_APP_SECONDARY_COLOR || '#823185',
  );
  const [roomAmountColors, setRoomAmountColors] = useState({
    room1: '#4D6AA8',
    room2: '#7FA25A',
    room3: '#B8A136',
    room4: '#D46C40',
    room5OrMore: '#AC40D4',
  });
  const [compartmentsColors, setCompartmentsColors] = useState({
    livingRoom: '#4D6AA8',
    bathroom: '#7FA25A',
    kitchen: '#D46C40',
    bedroom: '#B8A136',
    terrace: '#c2b280',
    wc: '#fc6868',
  });
  useStyles({ theme: { ...roomAmountColors, ...compartmentsColors } });

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await getStyleData();

      if (fetchedData) {
        const { data } = fetchedData;
        setIsMapHidden(data.attributes.HideMap);
        setListType(data.attributes.ApartmentsList);
        setPrimaryColor(data.attributes.PrimaryColor);
        setPrimaryColorDark(data.attributes.PrimaryColorDark);
        setRoomAmountColors({
          room1: data.attributes.OneRoomColor,
          room2: data.attributes.TwoRoomsColor,
          room3: data.attributes.ThreeRoomsColor,
          room4: data.attributes.FourRoomsColor,
          room5OrMore: data.attributes.FiveRoomsColor,
        });
        setCompartmentsColors({
          livingRoom: data.attributes.LivingRoomColor,
          bathroom: data.attributes.BathroomColor,
          kitchen: data.attributes.KitchenColor,
          bedroom: data.attributes.BedroomColor,
          terrace: data.attributes.TerraceColor,
          wc: data.attributes.WCColor,
        });
      }
    };

    fetchData();
  }, []);

  return (
    <StyleContext.Provider
      value={{
        isMapHidden,
        listType,
        primaryColor,
        primaryColorDark,
        roomAmountColors,
        compartmentsColors,
      }}
    >
      {children}
    </StyleContext.Provider>
  );
};
