import ReactDOM from 'react-dom/client';
import ReactTooltip from 'react-tooltip';

import App from './App';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'styles/globals.scss';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <>
    <App />
    <ReactTooltip place="left" effect="solid" />
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
