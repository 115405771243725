import { useContext } from 'react';
import { createUseStyles } from 'react-jss';

import DetailElement from 'components/DetailElement';
import { WORLD_DIRECTION_NAMES } from 'constants/options';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import { CustomTheme } from 'types/Style';
// import { calculateInsolation } from 'utils/calculateInsolation';

import styles from './styles.module.scss';
import { Props } from './types';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  detailsWrapper: {
    backgroundColor: theme.primary,
  },
}));

const ApartmentDetails = ({ apartment }: Props) => {
  const { t } = useContext(TranslationContext);
  const worldDirections: { [k: string]: string } = WORLD_DIRECTION_NAMES(t);
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });

  const buildApartmentDirectionElement = (direction: string) => {
    const directions = direction.split(',');
    return (
      <>
        {directions.map((direction, i) => (
          <span key={`directionDetail${i}`}>{worldDirections[direction]} </span>
        ))}
      </>
    );
  };

  return (
    <div>
      <div className={styles.title}>
        {t('flat')}
        {'\u00a0\u00a0\u00a0'}
        <b>{apartment.number.replace('_', ' ')}</b>
        {',\u00a0\u00a0\u00a0'}
        <b>
          {apartment.area}m<sup>2</sup>
        </b>
      </div>
      {/* <div className={styles.investmentName}>
        {t('investment')} {projectData._comment}
      </div>
      <div className={styles.streetName}>{projectData.address._comment}</div> */}
      <div
        className={[classes.detailsWrapper, styles.detailsWrapper].join(' ')}
      >
        <div className={styles.leftCol}>
          {/* <DetailElement
            caption={t('area')}
            dynamicValue={() => (
              <span>
                {apartment.area}m<sup>2</sup>
              </span>
            )}
          /> */}
          <DetailElement
            caption={t('roomsAmount')}
            value={apartment.rooms.toString()}
          />
          {/* <DetailElement
            caption={t('gateNo')}
            value={apartment.gate.toString()}
          /> */}
          {/* {apartment.insolation ? (
            <DetailElement
              caption={t('insolation')}
              dynamicValue={() => (
                <span>{calculateInsolation(apartment.insolation)}</span>
              )}
            />
          ) : null} */}
        </div>
        <div className={styles.rightCol}>
          {apartment.terraces ? (
            <DetailElement
              caption={t('terraceArea')}
              dynamicValue={() => (
                <span>
                  {apartment.terraces}m<sup>2</sup>
                </span>
              )}
            />
          ) : null}
          {apartment.exposition ? (
            <DetailElement
              caption={t('worldDirections')}
              dynamicValue={() =>
                buildApartmentDirectionElement(apartment.exposition)
              }
            />
          ) : null}
          {/* <DetailElement
            caption={t('floors')}
            value={apartment.levels.toString()}
          /> */}
          {/* <DetailElement caption={t('flatNo')} value={apartment.address_part} /> */}
        </div>
      </div>
    </div>
  );
};

export default ApartmentDetails;
