import { useMemo } from 'react';
import { IntlProvider } from 'react-intl';

import Layout from 'components/Layout';
import { config } from 'config';
import { ApartmentsFilterProvider } from 'context/apartmentsFilterContext';
import { ContactModalProvider } from 'context/contactModalContext';
import { DataProvider } from 'context/dataContext';
import { NavigationProvider } from 'context/navigationContext';
import { SettingsProvider } from 'context/settingsContext';
import { StyleProvider } from 'context/styleContext';
import { TranslationProvider } from 'context/translationContext';
import en from 'locale/en.json';
import pl from 'locale/pl.json';

const importMessages = (locale: string) => {
  switch (locale) {
    case 'pl':
      return pl;
    case 'en':
      return en;
    default:
      return pl;
  }
};

const App = () => {
  const { locale } = config;

  const messages = useMemo(() => importMessages(locale), []);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <TranslationProvider>
        <StyleProvider>
          <DataProvider>
            <NavigationProvider>
              <ContactModalProvider>
                <ApartmentsFilterProvider>
                  <SettingsProvider>
                    <Layout />
                  </SettingsProvider>
                </ApartmentsFilterProvider>
              </ContactModalProvider>
            </NavigationProvider>
          </DataProvider>
        </StyleProvider>
      </TranslationProvider>
    </IntlProvider>
  );
};

export default App;
