import { useContext, useMemo } from 'react';

import { ReactComponent as Logo } from 'assets/icons/logo_aplus.svg';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import { COMPARTMENTS_COLORS_DESCRIPTION } from 'utils/legend';

import styles from './styles.module.scss';

const ApartmentLegend = () => {
  const { t } = useContext(TranslationContext);
  const { compartmentsColors } = useContext(StyleContext);

  const compartmentIcons = useMemo(
    () => COMPARTMENTS_COLORS_DESCRIPTION(t, compartmentsColors),
    [],
  );

  return (
    <div className={styles.container}>
      <span className={styles.label}>{t('legend')}</span>
      {compartmentIcons.map(desc => (
        <div key={desc.id} className={styles.colorWrapper}>
          <div
            className={styles.colorDesc}
            style={{ backgroundColor: desc.color }}
          ></div>
          <span className={styles.colorLabel}>{desc.label}</span>
        </div>
      ))}
      <div className={styles.logoWrapper}>
        <Logo />
      </div>
    </div>
  );
};

export default ApartmentLegend;
