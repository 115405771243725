import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

import { ThreeDOverlay } from 'types/ThreeDOverlays';

export interface SettingsContext {
  selectedOverlay: ThreeDOverlay;
  setSelectedOverlay: Dispatch<SetStateAction<ThreeDOverlay>>;
}

interface SettingsContextProps {
  children: ReactNode;
}

export const SettingsContext = createContext<SettingsContext>({
  selectedOverlay: 'apartments',
  setSelectedOverlay: () => {},
});

export const SettingsProvider = ({ children }: SettingsContextProps) => {
  const [selectedOverlay, setSelectedOverlay] =
    useState<ThreeDOverlay>('apartments');

  return (
    <SettingsContext.Provider
      value={{
        selectedOverlay,
        setSelectedOverlay,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
