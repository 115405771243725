import {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

export interface NavigationContext {
  selectedFloor: number | null;
  setSelectedFloor: Dispatch<SetStateAction<number | null>>;
  selectedAptId: string | null;
  setSelectedAptId: Dispatch<SetStateAction<string | null>>;
}

interface NavigationContextProps {
  children: ReactNode;
}

export const NavigationContext = createContext<NavigationContext>({
  selectedFloor: null,
  setSelectedFloor: () => {},
  selectedAptId: null,
  setSelectedAptId: () => {},
});

export const NavigationProvider = ({ children }: NavigationContextProps) => {
  const [selectedFloor, setSelectedFloor] = useState<number | null>(null);
  const [selectedAptId, setSelectedAptId] = useState<string | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
    } else {
      setTimeout(() => {
        const height = document.getElementById('root')?.offsetHeight;
        window.parent.postMessage(['setScreen', height], '*');
      }, 500);
    }
  }, []);

  return (
    <NavigationContext.Provider
      value={{
        selectedFloor,
        setSelectedFloor,
        selectedAptId,
        setSelectedAptId,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
