import { useContext } from 'react';
import { createUseStyles } from 'react-jss';

import { DataContext } from 'context/dataContext';
import { StyleContext } from 'context/styleContext';
import { CustomTheme } from 'types/Style';
import { inRange } from 'utils/inRange';

import styles from './styles.module.scss';
import { Props } from './types';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  rangeButton: {
    color: theme.primary,
  },
  selectedRangeButton: {
    border: `1px solid ${theme.primary}`,
    backgroundColor: theme.primary,
  },
}));

const InputRangeButton = ({
  minValue,
  maxValue,
  maxValueKey,
  minValueKey,
  handleChange,
}: Props) => {
  const { roomsOptions } = useContext(DataContext);
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });
  const maxSelectedNumber = parseInt(maxValue);
  const minSelectedNumber = parseInt(minValue);

  const handleRangeChange = (value: string) => {
    const intValue = parseInt(value);

    if (!minSelectedNumber && !maxSelectedNumber) {
      handleChange(minValueKey, value);
      handleChange(maxValueKey, value);
      return;
    }

    if (
      minSelectedNumber === maxSelectedNumber &&
      minSelectedNumber === intValue
    ) {
      handleChange(minValueKey, '');
      handleChange(maxValueKey, '');
      return;
    }

    if (intValue < minSelectedNumber) {
      handleChange(minValueKey, value);
      return;
    }

    if (intValue === minSelectedNumber) {
      handleChange(minValueKey, (minSelectedNumber + 1).toString());
      return;
    }

    if (intValue > maxSelectedNumber) {
      handleChange(maxValueKey, value);
      return;
    }

    if (intValue === maxSelectedNumber) {
      handleChange(maxValueKey, (maxSelectedNumber - 1).toString());
      return;
    }

    if (intValue > minSelectedNumber && intValue < maxSelectedNumber) {
      handleChange(maxValueKey, value);
      return;
    }
  };

  return (
    <div className={styles.container}>
      {roomsOptions.map(n => {
        const buttonStyles = inRange(n, minSelectedNumber, maxSelectedNumber)
          ? [
              classes.rangeButton,
              classes.selectedRangeButton,
              styles.rangeButton,
              styles.selectedRangeButton,
            ]
          : [classes.rangeButton, styles.rangeButton];
        return (
          <button
            key={n}
            onClick={(e: any) => {
              handleRangeChange(e.target.innerText);
            }}
            className={buttonStyles.join(' ')}
          >
            {n}
          </button>
        );
      })}
    </div>
  );
};

export default InputRangeButton;
