import { Row } from '@tanstack/react-table';

import { Compartment } from 'types/Apartments';
import intParser from 'utils/intParser';

export const floorFilter = (
  row: Row<any>,
  columnId: string,
  filterValue: any,
): boolean => {
  const { compartments } = row.original;
  const minFloor = intParser(filterValue[0]);
  const maxFloor = intParser(filterValue[1]);

  // filter empty
  if (!minFloor && !maxFloor) return true;

  // no info about floor - hide record.
  if (!compartments) return false;

  const floors: number[] = Array.from(
    new Set<number>(compartments.map((x: Compartment) => x.ref_tier)),
  );

  // only minFloor typed
  if (minFloor && !maxFloor) return floors.some(x => x >= minFloor);

  // only maxFloor typed
  if (maxFloor && !minFloor) return floors.some(x => x <= maxFloor);

  // min and max typed
  return floors.some(x => x >= minFloor && x <= maxFloor);
};
