import {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { isMobile } from 'react-device-detect';
import { createUseStyles } from 'react-jss';
import Masonry from 'react-masonry-css';
import { ReactSVG } from 'react-svg';

import ImageExpand from 'components/ImageExpand';
import Tooltip from 'components/Tooltip';
import { DataContext } from 'context/dataContext';
import { NavigationContext } from 'context/navigationContext';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import { CustomTheme } from 'types/Style';
import { importAllFiles } from 'utils/importAllFiles';
import { scrollToSection } from 'utils/scrollToSection';

import styles from './styles.module.scss';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  container: {
    '& .outline path:hover': {
      fill: theme.primary,
      stroke: theme.primary,
    },
  },
  image: {
    border: `1px solid ${theme.primary}`,
  },
  grid: {
    backgroundColor: theme.primary,
  },
}));

const GalleryView = () => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [floor, setFloor] = useState('');
  const [flatsAmount, setFlatsAmount] = useState('');
  const [expandedImage, setExpandedImage] = useState<string | undefined>(
    undefined,
  );
  const { setSelectedFloor } = useContext(NavigationContext);
  const { t } = useContext(TranslationContext);
  const { apartmentsPerFloorAmount } = useContext(DataContext);
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });
  const expandedStateRef = useRef();
  const tooltipStateRef = useRef();
  // @ts-ignore
  expandedStateRef.current = expandedImage;
  // @ts-ignore
  tooltipStateRef.current = isTooltipOpen;

  const openTooltip = useCallback((e: any) => {
    const id = e.target.id;

    if (id) {
      setFloor(id);
      setIsTooltipOpen(true);
      setFlatsAmount(apartmentsPerFloorAmount[id]);
    }
  }, []);

  const hideTooltip = useCallback(() => {
    setIsTooltipOpen(false);
  }, []);

  const navigateToFloor = useCallback((e: any) => {
    const id = e.target.id;

    if (id) {
      setSelectedFloor(parseInt(id));

      if (expandedStateRef.current) {
        hideTooltip();
        setExpandedImage(undefined);
        scrollToSection('floorsSection');
      }
    }
  }, []);

  const handleClick = (image: string) => {
    if (tooltipStateRef.current) {
      hideTooltip();
      scrollToSection('floorsSection');
    } else {
      setExpandedImage(image);
    }
  };

  useEffect(() => {
    return () => {
      const outlines = document.getElementsByClassName('outline');
      for (let i = 0; i < outlines.length; i++) {
        const paths = outlines[0].querySelectorAll('path');
        paths.forEach(path => {
          path.removeEventListener('mouseenter', openTooltip);
          path.removeEventListener('mouseleave', hideTooltip);
          path.removeEventListener('click', navigateToFloor);
        });
      }
    };
  }, []);

  const handleAfterInjection = useCallback(() => {
    const outlines = document.getElementsByClassName('outline');
    for (let i = 0; i < outlines.length; i++) {
      const paths = outlines[i].querySelectorAll('path');
      paths.forEach(path => {
        path.addEventListener('mouseenter', openTooltip);
        path.addEventListener('mouseleave', hideTooltip);
        path.addEventListener('click', navigateToFloor);
      });
    }
  }, []);

  const images = useMemo(() => {
    return [
      // @ts-ignore
      ...new Set(
        importAllFiles(
          require.context('data/vis', false, /\.(png|jpe?g|svg)$/),
        ),
      ),
    ];
  }, []);

  const filteredImages = useMemo(() => {
    const filtered: string[] = [];
    images.forEach(img => {
      const splitted = img.split('.');
      if (splitted[splitted.length - 1] !== 'svg') {
        filtered.push(img);
      }
    });
    return filtered;
  }, [images]);

  const renderImages = () => {
    const parsed: any[] = [];

    images.forEach((image, idx) => {
      const splittedImageUrl = image.split('.');
      if (splittedImageUrl[splittedImageUrl.length - 1] === 'svg') {
        return;
      }

      const splittedNextImageUrl = images[idx + 1]?.split('.');

      if (!splittedNextImageUrl) {
        parsed.push(
          <img
            key={image}
            src={image}
            className={[
              classes.image,
              styles.image,
              styles.imgWithoutOverlay,
            ].join()}
            onClick={() => setExpandedImage(image)}
          />,
        );
      }

      parsed.push(
        <div
          key={image}
          className={[styles.imageContainer, 'imageContainer'].join(' ')}
          onClick={() => handleClick(image)}
        >
          <img src={image} className={styles.image} />
          {splittedImageUrl[0] === splittedNextImageUrl[0] && (
            <ReactSVG
              src={images[idx + 1]}
              className={[styles.svgImage, 'outline'].join(' ')}
              renumerateIRIElements={false}
              afterInjection={handleAfterInjection}
            />
          )}
        </div>,
      );
    });
    return parsed;
  };

  return (
    <>
      <div className={[classes.container, styles.container].join(' ')}>
        <Masonry
          breakpointCols={isMobile ? 1 : 3}
          className={[classes.grid, styles.grid].join(' ')}
          columnClassName={styles.gridColumn}
        >
          {renderImages()}
        </Masonry>
        {expandedImage && (
          <ImageExpand
            expandedImage={expandedImage}
            onRemoveExpand={() => setExpandedImage(undefined)}
            images={filteredImages}
          >
            {renderImages()}
          </ImageExpand>
        )}
      </div>
      {!isMobile && (
        <Tooltip id="galleryTooltip" isOpen={isTooltipOpen}>
          <div className={styles.tooltip}>
            <p>
              {t('floor')}: <b>{floor === '0' ? t('groundFloor') : floor}</b>
            </p>
            <p>
              {t('availableApartments')}: <b>{flatsAmount}</b>
            </p>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default GalleryView;
