import { useContext, useState } from 'react';

import ShowMore from 'components/ShowMore';
import { TranslationContext } from 'context/translationContext';

import styles from './styles.module.scss';

const ApartmentDescription = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useContext(TranslationContext);

  const toggleOpen = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div>
      <span>{t('apartmentClaim')}</span>
      <br />
      <br />
      <ul>
        <li>{t('apartmentAdvantage1')}</li>
        <li>{t('apartmentAdvantage2')}</li>
        <li>{t('apartmentAdvantage3')}</li>
      </ul>
      {isOpen && (
        <>
          <br />
          <p>{t('apartmentDescription1')}</p>
          <br />
          <p>{t('apartmentExtraTitle')}</p>
          <ul className={styles.extraList}>
            <li>{t('apartmentExtra1')}</li>
            <li>{t('apartmentExtra2')}</li>
            <li>{t('apartmentExtra3')}</li>
            <li>{t('apartmentExtra4')}</li>
            <li>{t('apartmentExtra5')}</li>
          </ul>
          <br />
          <p>{t('apartmentDescription2')}</p>
        </>
      )}
      <ShowMore isOpen={isOpen} handleClick={toggleOpen} />
    </div>
  );
};

export default ApartmentDescription;
