import { useContext, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { ReactSVG } from 'react-svg';

import DetailElement from 'components/DetailElement';
import { AVAILABLE } from 'constants/apartmentStatus';
import { ApartmentsFilterContext } from 'context/apartmentsFilterContext';
import { DataContext } from 'context/dataContext';
import { NavigationContext } from 'context/navigationContext';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import { CustomTheme } from 'types/Style';
import { scrollToSection } from 'utils/scrollToSection';

import styles from './styles.module.scss';
import { ParsedApt, Props } from './types';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  container: {
    backgroundColor: theme.primary,
  },
  infoContainer: {
    backgroundColor: theme.primary,
  },
  showMore: {
    backgroundColor: `${theme.primary} !important`,
    '& button': {
      backgroundColor: `${theme.primary} !important`,
    },
  },
}));

const ITEMS_PER_PAGE = 16;

const ApartamentsTiles = ({ data }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { setSelectedAptId } = useContext(NavigationContext);
  const { apartmentsImages } = useContext(DataContext);
  const { filterValues } = useContext(ApartmentsFilterContext);
  const { t } = useContext(TranslationContext);
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({ theme: { primary: primaryColor } });

  const currentShown = useMemo(() => {
    return currentPage * ITEMS_PER_PAGE;
  }, [currentPage]);

  const parsedApartments = useMemo(() => {
    setCurrentPage(1);
    const parsed: ParsedApt[] = [];
    const { areaMin, areaMax, floorMin, floorMax, roomMin, roomMax } =
      filterValues;
    const parsedRoomMin = parseInt(roomMin);
    const parsedRoomMax = parseInt(roomMax);
    const parsedAreaMin = parseFloat(areaMin);
    const parsedAreaMax = parseFloat(areaMax);
    const parsedFloorMin = parseInt(floorMin);
    const parsedFloorMax = parseInt(floorMax);

    data.forEach(apt => {
      if (apt.dontShow !== true && apt.status === AVAILABLE) {
        const { rooms, area, compartments } = apt;
        let isRoomFilterPassed = true;
        let isAreaFilterPassed = true;
        let isFloorFilterPassed = true;

        if (parsedRoomMax && parsedRoomMax) {
          if (rooms >= parsedRoomMin && rooms <= parsedRoomMax) {
            isRoomFilterPassed = true;
          } else {
            isRoomFilterPassed = false;
          }
        }

        if (parsedAreaMin && !parsedAreaMax) {
          isAreaFilterPassed = area >= parsedAreaMin;
        }

        if (parsedAreaMax && !parsedAreaMin) {
          isAreaFilterPassed = area <= parsedAreaMax;
        }

        if (parsedAreaMax && parsedAreaMin) {
          isAreaFilterPassed = area >= parsedAreaMin && area <= parsedAreaMax;
        }

        if (parsedFloorMin && !parsedFloorMax) {
          isFloorFilterPassed =
            compartments.findIndex(cpt => cpt.ref_tier >= parsedFloorMin) !==
            -1;
        }

        if (parsedFloorMax && !parsedFloorMin) {
          isFloorFilterPassed =
            compartments.findIndex(cpt => cpt.ref_tier <= parsedFloorMax) !==
            -1;
        }

        if (parsedFloorMax && parsedFloorMin) {
          isFloorFilterPassed =
            compartments.findIndex(
              cpt =>
                cpt.ref_tier >= parsedFloorMin &&
                cpt.ref_tier <= parsedFloorMax,
            ) !== -1;
        }

        if (isRoomFilterPassed && isAreaFilterPassed && isFloorFilterPassed) {
          parsed.push({
            id: apt.id,
            number: apt.number,
            area: apt.area,
            rooms: apt.rooms,
            status: apt.status,
            compartments: apt.compartments,
            imgUrl: apartmentsImages.find(url => url.includes(apt.id)),
          });
        }
      }
    });

    return parsed;
  }, [data, filterValues]);

  const addColorToCompartments = () => {
    const tileImgs = document.getElementsByClassName('tileApartmentLayout');
    if (tileImgs.length !== 0) {
      for (let i = 0; i < tileImgs.length; i++) {
        const tileImg = tileImgs[i];
        const id = tileImg.id;
        const compartments = parsedApartments.find(
          el => el.id === id,
        )?.compartments;

        if (compartments) {
          const compartmentsSvgs = tileImg.getElementsByClassName('rm');
          for (let i = 0; i < compartmentsSvgs.length; i++) {
            const idx = compartmentsSvgs[i].getAttribute('ind');
            if (idx) {
              const compartment = compartments[parseInt(idx)];
              if (compartment) {
                compartmentsSvgs[i].classList.add(
                  compartment.name.split('.')[1],
                );
              }
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (parsedApartments.length !== 0) {
      setTimeout(() => {
        addColorToCompartments();
      }, 500);
    }
  }, [parsedApartments, currentPage]);

  const handleClick = (id: string) => {
    setSelectedAptId(id);
    scrollToSection('apartmentSection');
  };

  const handleShowMoreClick = () => {
    setCurrentPage(prevState => prevState + 1);
  };

  return (
    <div className={[classes.container, styles.container].join(' ')}>
      {parsedApartments.map((apartment, idx) => {
        if (idx + 1 <= currentShown) {
          return (
            <div key={apartment.id} className={styles.tileContainer}>
              <div
                className={styles.tileWrapper}
                onClick={() => handleClick(apartment.id)}
              >
                <div className={styles.imgWrapper}>
                  <ReactSVG
                    id={apartment.id}
                    className="tileApartmentLayout"
                    src={apartment.imgUrl}
                  />
                </div>
                <div
                  className={[classes.infoContainer, styles.infoContainer].join(
                    ' ',
                  )}
                >
                  <div className={styles.infoWrapper}>
                    <DetailElement
                      caption={t('flat')}
                      value={apartment.number.replace('_', ' ')}
                    />
                    <DetailElement
                      caption={t('area')}
                      dynamicValue={() => (
                        <span>
                          {apartment.area}m<sup>2</sup>
                        </span>
                      )}
                    />
                  </div>
                  <div className={styles.infoWrapper}>
                    <DetailElement
                      caption={t('roomsAmountTiles')}
                      value={apartment.rooms.toString()}
                    />
                    <DetailElement
                      caption={t('status')}
                      value={
                        apartment.status === AVAILABLE
                          ? t('available')
                          : t('sold')
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
      {currentShown < parsedApartments.length ? (
        <div className={[classes.showMore, styles.showMore].join(' ')}>
          <button onClick={handleShowMoreClick}>{t('showMore')}</button>
        </div>
      ) : null}
    </div>
  );
};

export default ApartamentsTiles;
