import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { createUseStyles } from 'react-jss';
import { ReactSVG } from 'react-svg';

import Legend from 'components/Legend';
import Tooltip from 'components/Tooltip';
import Visualization from 'components/Visualization';
import { AVAILABLE } from 'constants/apartmentStatus';
import { ApartmentsFilterContext } from 'context/apartmentsFilterContext';
import { DataContext } from 'context/dataContext';
import { NavigationContext } from 'context/navigationContext';
import { SettingsContext } from 'context/settingsContext';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import projectData from 'data/project.json';
import { Compartment } from 'types/Apartments';
import { CustomTheme } from 'types/Style';
import { ThreeDOverlay } from 'types/ThreeDOverlays';
import { importImagesFromProject } from 'utils/importImagesFromProject';
import 'data/icons.css';
import { scrollToSection } from 'utils/scrollToSection';

import styles from './styles.module.scss';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  sold: {
    color: theme.primary,
  },
}));

const ThreeDView = () => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [floor, setFloor] = useState('');
  const [flatsAmount, setFlatsAmount] = useState('');
  const [ofertNo, setOfertNo] = useState('');
  const [roomsAmount, setRoomsAmount] = useState('');
  const [aptImage, setAptImage] = useState('');
  const [aptRooms, setAptRooms] = useState<Compartment[]>([]);
  const [area, setArea] = useState('');
  const [isSold, setIsSold] = useState(false);
  const [compassAngle, setCompassAngle] = useState(0);
  const [currentImg, setCurrentImg] = useState(59);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const { selectedOverlay, setSelectedOverlay } = useContext(SettingsContext);
  const { t } = useContext(TranslationContext);
  const { setSelectedFloor, setSelectedAptId } = useContext(NavigationContext);
  const { filterValues } = useContext(ApartmentsFilterContext);
  const { apartments, apartmentsPerFloorAmount, apartmentsImages } =
    useContext(DataContext);
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });
  const { roomMin, roomMax, areaMin, areaMax, floorMin, floorMax } =
    filterValues;

  const images = useMemo(importImagesFromProject, []);
  const imagesCount = useMemo(() => images.length, [images]);

  const openTooltip = useCallback((e: any) => {
    const id = e.target.id;
    let shouldShowTooltip = true;

    if (id) {
      if (id.length > 2) {
        const aptData = apartments.find(apt => apt.id === id);
        const imgUrl = apartmentsImages.find(url => url.includes(id));

        if (aptData && imgUrl) {
          setIsSold(aptData.status !== AVAILABLE);
          setOfertNo(aptData.number.replace('_', '.'));
          setArea(aptData.area.toString());
          setRoomsAmount(aptData.rooms.toString());
          setAptRooms(aptData.compartments);
          setAptImage(imgUrl);
          if (aptData.dontShow) {
            shouldShowTooltip = false;
          }
        }
      } else {
        setFloor(id);
        setFlatsAmount(apartmentsPerFloorAmount[id]);
      }

      if (shouldShowTooltip) {
        setIsTooltipOpen(true);
      } else {
        e.target.style.fill = 'transparent';
        e.target.style.cursor = 'grab';
        hideTooltip();
      }
    }
  }, []);

  const hideTooltip = useCallback(() => {
    setIsTooltipOpen(false);
    setIsSold(false);
  }, []);

  const handleClick = useCallback((e: any) => {
    const id = e.target.id;

    if (id) {
      if (id.length > 2) {
        const aptData = apartments.find(apt => apt.id === id);
        if (
          aptData &&
          aptData.status === AVAILABLE &&
          aptData.dontShow === false
        ) {
          setSelectedAptId(id);
          scrollToSection('apartmentSection');
        }
      } else {
        setSelectedFloor(parseInt(id));
        scrollToSection('floorsSection');
      }
    }
  }, []);

  const addListeners = () => {
    const outlines = document.getElementsByClassName('active');
    if (outlines.length !== 0) {
      outlines[0].addEventListener('mouseleave', hideTooltip);
      const paths = outlines[0].querySelectorAll('path');
      paths.forEach(path => {
        if (!isMobile) {
          path.addEventListener('mouseenter', openTooltip);
          path.addEventListener('onmouseout', hideTooltip);
        }
        path.addEventListener('click', handleClick);
      });
    }
  };

  const removeListeners = () => {
    const outlines = document.getElementsByClassName('active');
    if (outlines.length !== 0) {
      const paths = outlines[0].querySelectorAll('path');
      paths.forEach(path => {
        if (!isMobile) {
          path.removeEventListener('mouseenter', openTooltip);
          path.removeEventListener('mouseleave', hideTooltip);
        }
        path.removeEventListener('click', handleClick);
      });
    }
  };

  const addActiveClass = (idx: number) => {
    const activeG = document.querySelector('#overlay .active');
    if (activeG) {
      activeG.classList.remove('active');
    }

    const g = document.querySelectorAll('#overlay g');
    if (g.length !== 0 && !!g[idx]) {
      g[idx].classList.add('active');
    }
  };

  const handleOverlays = (idx = currentImg) => {
    addActiveClass(idx);
    removeListeners();
    addListeners();
  };

  const addApartmentRoomsClasses = () => {
    if (selectedOverlay === 'apartments') {
      const outlines = document.getElementsByClassName('active');
      if (outlines.length !== 0) {
        const paths = outlines[0].querySelectorAll('path');
        paths.forEach(path => {
          const apartmentInfo = apartments.find(apt => apt.id === path.id);
          if (apartmentInfo) {
            if (!apartmentInfo.status) {
              return;
            }

            let isRoomFilterPassed = true;
            let isAreaFilterPassed = true;
            let isFloorFilterPassed = true;
            const parsedRoomMin = parseInt(roomMin);
            const parsedRoomMax = parseInt(roomMax);
            const parsedAreaMin = parseFloat(areaMin);
            const parsedAreaMax = parseFloat(areaMax);
            const parsedFloorMin = parseInt(floorMin);
            const parsedFloorMax = parseInt(floorMax);
            const aptRooms = apartmentInfo.rooms;
            const area = apartmentInfo.area;
            const compartments = apartmentInfo.compartments;

            if (parsedRoomMax && parsedRoomMax) {
              if (aptRooms >= parsedRoomMin && aptRooms <= parsedRoomMax) {
                isRoomFilterPassed = true;
              } else {
                isRoomFilterPassed = false;
              }
            }

            if (parsedAreaMin && !parsedAreaMax) {
              isAreaFilterPassed = area >= parsedAreaMin;
            }

            if (parsedAreaMax && !parsedAreaMin) {
              isAreaFilterPassed = area <= parsedAreaMax;
            }

            if (parsedAreaMax && parsedAreaMin) {
              isAreaFilterPassed =
                area >= parsedAreaMin && area <= parsedAreaMax;
            }

            if (parsedFloorMin && !parsedFloorMax) {
              isFloorFilterPassed =
                compartments.findIndex(
                  cpt => cpt.ref_tier >= parsedFloorMin,
                ) !== -1;
            }

            if (parsedFloorMax && !parsedFloorMin) {
              isFloorFilterPassed =
                compartments.findIndex(
                  cpt => cpt.ref_tier <= parsedFloorMax,
                ) !== -1;
            }

            if (parsedFloorMax && parsedFloorMin) {
              isFloorFilterPassed =
                compartments.findIndex(
                  cpt =>
                    cpt.ref_tier >= parsedFloorMin &&
                    cpt.ref_tier <= parsedFloorMax,
                ) !== -1;
            }

            if (
              isRoomFilterPassed &&
              isAreaFilterPassed &&
              isFloorFilterPassed &&
              apartmentInfo.dontShow === false
            ) {
              if (apartmentInfo.status === AVAILABLE) {
                path.classList.add(`room-${aptRooms}`);
              } else {
                path.classList.add('room-unavailable');
              }
            } else {
              if (apartmentInfo.status === AVAILABLE) {
                path.classList.remove(`room-${aptRooms}`);
              } else {
                path.classList.remove('room-unavailable');
              }
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    return () => {
      removeListeners();
    };
  }, []);

  useEffect(() => {
    handleOverlays();
  }, []);

  useEffect(() => {
    addApartmentRoomsClasses();
  }, [roomMin, roomMax, areaMin, areaMax, floorMin, floorMax, apartments]);

  useEffect(() => {
    handleOverlays();
    addApartmentRoomsClasses();
  }, [selectedOverlay]);

  useEffect(() => {
    handleOverlays(currentImg);
    addApartmentRoomsClasses();
    calculateCompassAngle();
  }, [currentImg]);

  const handlePrevClick = () => {
    setCurrentImg(prevVal => {
      if (prevVal === 0) {
        return imagesCount - 1;
      } else {
        return prevVal - 1;
      }
    });
  };

  const handleNextClick = () => {
    setCurrentImg(prevVal => {
      if (prevVal === imagesCount - 1) {
        return 0;
      } else {
        return prevVal + 1;
      }
    });
  };

  const toggleOverlayVisbility = () => {
    if (isOverlayVisible) {
      const paths = document.querySelectorAll('#overlay');
      // @ts-ignore
      paths.forEach(el => (el.style.display = 'none'));
      setIsOverlayVisible(false);
    } else {
      const paths = document.querySelectorAll('#overlay');
      // @ts-ignore
      paths.forEach(el => el.style.removeProperty('display'));
      setIsOverlayVisible(true);
    }
  };

  const handleOverlayChange = (_: string, selected: ThreeDOverlay) => {
    setSelectedOverlay(selected);
  };

  const calculateCompassAngle = () => {
    const { address } = projectData;
    const angle = (360 / 72) * currentImg - address.north_ang;
    setCompassAngle(angle);
  };

  const handleAfterInjection = useCallback(() => {
    const tooltipImg = document.getElementById('tooltipApartmentLayout');
    if (tooltipImg) {
      const compartments = tooltipImg.getElementsByClassName('rm');
      for (let i = 0; i < compartments.length; i++) {
        const idx = compartments[i].getAttribute('ind');

        if (idx) {
          const compartment = aptRooms[parseInt(idx)];
          if (compartment) {
            compartments[i].classList.add(compartment.name.split('.')[1]);
          }
        }
      }
    }
  }, [aptRooms]);

  return (
    <>
      <div className={styles.container}>
        <div className={[styles.leftCol, 'threeDView'].join(' ')}>
          <Visualization
            isOverlayVisible={isOverlayVisible}
            selectedOverlay={selectedOverlay}
            currentImg={currentImg}
            images={images}
            toggleOverlayVisbility={toggleOverlayVisbility}
            handlePrevClick={handlePrevClick}
            handleNextClick={handleNextClick}
            handleOverlayChange={handleOverlayChange}
            compassAngle={compassAngle}
          />
          <Legend />
        </div>
      </div>
      {!isMobile && (
        <Tooltip id="threeDTooltip" isOpen={isTooltipOpen}>
          <div className={styles.tooltip}>
            {selectedOverlay === 'floors' ? (
              <>
                <p>
                  {t('floor')}:{' '}
                  <b>{floor === '0' ? t('groundFloor') : floor}</b>
                </p>
                <p>
                  {t('availableApartments')}: <b>{flatsAmount}</b>
                </p>
              </>
            ) : (
              <>
                {isSold ? (
                  <p className={[classes.sold, styles.sold].join(' ')}>
                    {t('sold')}
                  </p>
                ) : (
                  <>
                    <ReactSVG
                      id="tooltipApartmentLayout"
                      src={aptImage}
                      afterInjection={handleAfterInjection}
                    />
                    <p>
                      {t('offerNumber')}: <b>{ofertNo}</b>
                    </p>
                    <p>
                      {t('roomsAmount')}: <b>{roomsAmount}</b>
                    </p>
                    <p>
                      {t('area')}:{' '}
                      <b>
                        {area}m<sup>2</sup>
                      </b>
                    </p>
                  </>
                )}
              </>
            )}
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default ThreeDView;
