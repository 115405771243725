import { useContext, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';

import RotateLeft from 'assets/icons/rotate-arrow-left.png';
import RotateRight from 'assets/icons/rotate-arrow-right.png';
import { StyleContext } from 'context/styleContext';
import { CustomTheme } from 'types/Style';

import styles from './styles.module.scss';
import { Props } from './types';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  container: {
    backgroundColor: theme.primary,
  },
}));

const RotateControl = ({ onPrevClick, onNextClick }: Props) => {
  const prevTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const nextTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });

  useEffect(() => {
    return () => {
      if (prevTimer.current) {
        clearTimeout(prevTimer.current);
      }
      if (nextTimer.current) {
        clearTimeout(nextTimer.current);
      }
    };
  }, []);

  const resetHold = () => {
    if (prevTimer.current) {
      clearTimeout(prevTimer.current);
    }
    if (nextTimer.current) {
      clearTimeout(nextTimer.current);
    }
  };

  const handlePrevClick = () => {
    onPrevClick();
    prevTimer.current = setTimeout(handlePrevClick, 150);
  };

  const handleNextClick = () => {
    onNextClick();
    nextTimer.current = setTimeout(handleNextClick, 150);
  };

  return (
    <div className={[classes.container, styles.container].join(' ')}>
      <button
        className={styles.iconWrapper}
        onMouseDown={handlePrevClick}
        onMouseUp={resetHold}
        onMouseOut={resetHold}
      >
        <img src={RotateLeft} className={styles.icon} draggable={false} />
      </button>
      <button
        className={styles.iconWrapper}
        onMouseDown={handleNextClick}
        onMouseUp={resetHold}
        onMouseOut={resetHold}
      >
        <img src={RotateRight} className={styles.icon} draggable={false} />
      </button>
    </div>
  );
};

export default RotateControl;
