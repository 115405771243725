import { importAllFiles } from 'utils/importAllFiles';

export const importProjectData = () => {
  const fetched = importAllFiles(
    require.context('data', false, /project\.(json)$/),
  );
  // @ts-ignore
  const unique = [...new Set(fetched)];

  return unique[0];
};
