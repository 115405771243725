import { useContext } from 'react';

import { TranslationContext } from 'context/translationContext';

import styles from './styles.module.scss';
import { Props } from './types';

const ShowMore = ({ isOpen, handleClick }: Props) => {
  const { t } = useContext(TranslationContext);

  return (
    <div className={styles.container}>
      <button className={styles.btn} onClick={handleClick}>
        {isOpen ? `${t('hide')}` : `${t('showMore')}`}
      </button>
    </div>
  );
};

export default ShowMore;
