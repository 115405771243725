import { Row } from '@tanstack/react-table';

import intParser from 'utils/intParser';

export const pricePerMeterFilter = (
  row: Row<any>,
  columnId: string,
  filterValue: any,
): boolean => {
  // TODO: write price per meter filter
  const { price, area } = row.original;
  const minPrice = intParser(filterValue[0]);
  const maxPrice = intParser(filterValue[1]);

  // filter empty
  if (!minPrice && !maxPrice) return true;

  // no info about price - hide record.
  if (!price) return false;

  const pricePerMeter = price / area;

  // only minPrice typed
  if (minPrice && !maxPrice) return pricePerMeter >= minPrice;

  // only maxPrice typed
  if (maxPrice && !minPrice) return pricePerMeter <= maxPrice;

  // min & max typed
  return pricePerMeter >= minPrice && pricePerMeter <= maxPrice;
};
