import { useContext } from 'react';
import { createUseStyles } from 'react-jss';

import { ApartmentsFilterContext } from 'context/apartmentsFilterContext';
import { DataContext } from 'context/dataContext';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import { CustomTheme } from 'types/Style';
import intParser from 'utils/intParser';
import { ROOMS_COLORS_DESCRIPTION } from 'utils/legend';

import styles from './styles.module.scss';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  container: {
    backgroundColor: theme.primary,
  },
}));

const Legend = () => {
  const { filterValues, setFilterValue } = useContext(ApartmentsFilterContext);
  const { roomsOptions } = useContext(DataContext);
  const { t } = useContext(TranslationContext);
  const { primaryColor, roomAmountColors } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });

  const roomIcons = ROOMS_COLORS_DESCRIPTION(t, roomAmountColors).slice(
    0,
    roomsOptions.length,
  );

  const { roomMin, roomMax } = filterValues;
  const roomMinNumber = intParser(roomMin);
  const roomMaxNumber = intParser(roomMax);

  const isSelected = (id: string) => {
    const selectedId = intParser(id);
    return selectedId >= roomMinNumber && selectedId <= roomMaxNumber;
  };

  const handleSetRoomFilter = (id: string) => {
    if (isSelected(id)) {
      setFilterValue('roomMin', '');
      setFilterValue('roomMax', '');
    } else {
      setFilterValue('roomMin', id);
      setFilterValue('roomMax', id);
    }
  };

  return (
    <div className={[classes.container, styles.container].join(' ')}>
      <span className={styles.label}>{t('legend')}</span>
      {roomIcons.map(desc => (
        <div
          key={desc.color}
          className={styles.colorWrapper}
          onClick={() => handleSetRoomFilter(desc.id)}
        >
          <div
            className={[
              styles.colorDesc,
              isSelected(desc.id) && styles.colorDescSelected,
            ].join(' ')}
            style={{ backgroundColor: desc.color }}
          ></div>
          <span
            className={[
              styles.colorLabel,
              isSelected(desc.id) && styles.colorLabelSelected,
            ].join(' ')}
          >
            {desc.label}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Legend;
