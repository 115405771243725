import img1 from '../../assets/images/tabelaofert.svg';

const PartnerGallery = () => {
  return (
    <a className="partnerLogo" href="https://tabelaofert.pl" target="_blank">
      <img src={img1} alt="Tabela ofert" />;
    </a>
  );
};

export default PartnerGallery;
