export const PRICE_OPTIONS = [
  '200 000',
  '250 000',
  '350 000',
  '500 000',
  '700 000',
  '900 000',
  '1 250 000',
  '1 750 000',
  '2 000 000',
  '3 000 000',
];

export const PRICE_PER_METER_OPTIONS = [
  '4 000',
  '4 500',
  '5 500',
  '6 500',
  '7 500',
  '9 000',
  '10 500',
  '12 500',
  '14 500',
  '17 000',
];

export const EXTERNAL_AREA_OPTIONS = (t: (id: string) => string) => {
  return [
    {
      label: t('terraceOrGarden'),
      value: 'taras',
    },
    // {
    //   label: t('balcony'),
    //   value: 'balkon',
    // },
  ];
};

export enum FlatPlacementValues {
  tylkoOstatniePietro = 'tylkoOstatniePietro',
  bezOstatniegoPietra = 'bezOstatniegoPietra',
  tylkoParter = 'tylkoParter',
  bezParteru = 'bezParteru',
}

export const FLAT_PLACEMENT_OPTIONS = (t: (id: string) => string) => {
  return [
    {
      label: t('lastFloorOnly'),
      value: 'tylkoOstatniePietro',
    },
    {
      label: t('notLastFloor'),
      value: 'bezOstatniegoPietra',
    },
    {
      label: t('groundFloorOnly'),
      value: 'tylkoParter',
    },
    {
      label: t('notGroundFloor'),
      value: 'bezParteru',
    },
  ];
};

export const KITCHEN_OPTIONS = (t: (id: string) => string) => {
  return [
    {
      label: t('separateKitchen'),
      value: 'kuchniaJakoOsobne',
    },
    {
      label: t('kitchenette'),
      value: 'aneksKuchenny',
    },
  ];
};

export const DIRECTIONS = (t: (id: string) => string) => {
  return [
    {
      label: t('fromNorth'),
      value: 'polnoc',
    },
    {
      label: t('fromSouth'),
      value: 'poludnie',
    },
    {
      label: t('fromEast'),
      value: 'wschod',
    },
    {
      label: t('fromWest'),
      value: 'zachod',
    },
  ];
};

export const THREE_D_OVERLAY_OPTIONS = (t: (id: string) => string) => {
  return [
    {
      label: t('flats'),
      value: 'apartments',
    },
    {
      label: t('floors'),
      value: 'floors',
    },
  ];
};

export const COMPARTMENT_NAMES = (t: (id: string) => string) => {
  return {
    'id.salon': t('livingRoom'),
    'id.lazienka': t('bathroom'),
    'id.kuchnia': t('kitchen'),
    'id.sypialnia': t('bedroom'),
    'id.taras': t('terrace'),
    'id.wc': t('wc'),
    'id.garderoba': t('wardrobe'),
  };
};

export const WORLD_DIRECTION_NAMES = (t: (id: string) => string) => {
  return {
    N: t('fromNorth'),
    NE: t('fromNorthEast'),
    NW: t('fromNorthWest'),
    S: t('fromSouth'),
    SE: t('fromSouthEast'),
    SW: t('fromSouthWest'),
    E: t('fromEast'),
    W: t('fromWest'),
  };
};
