import { ReactComponent as ChevronDown } from 'assets/icons/chevronDownIcon.svg';

import styles from './styles.module.scss';
import { Props } from './types';

const GalleryArrow = ({
  clickHandler,
  hasPrev,
  dir,
  isInExpand = false,
  isWide = false,
}: Props) => {
  const getArrowStyle = () => {
    if (dir === 'left') {
      return isWide ? styles.wideLeftArrow : styles.leftArrow;
    }
    return isWide ? styles.wideRightArrow : styles.rightArrow;
  };

  return (
    <div
      onClick={clickHandler}
      aria-hidden="true"
      className={[
        styles.chevronContainer,
        isInExpand && styles.inExpandContainer,
        getArrowStyle(),
        !hasPrev && styles.disabledArrow,
      ].join(' ')}
    >
      <ChevronDown />
    </div>
  );
};

export default GalleryArrow;
