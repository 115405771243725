import { useContext, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import { CustomTheme } from 'types/Style';

import styles from './styles.module.scss';
import { Props } from './types';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  container: {
    backgroundColor: theme.primary,
  },
  filterDropdown: {
    backgroundColor: theme.primaryDark,
  },
}));

const FilterItem = ({
  label,
  value,
  staticValue,
  children,
  onClick,
  onReset,
}: Props) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { t } = useContext(TranslationContext);
  const dropdownRef = useRef(null);
  const { primaryColor, primaryColorDark } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor, primaryDark: primaryColorDark },
  });

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!dropdownRef.current.contains(e.target)) hideFilter();
  };

  useEffect(() => {
    if (isFilterOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isFilterOpen]);

  const hideFilter = () => {
    setIsFilterOpen(false);
  };

  const resetFilter = () => {
    hideFilter();
    onReset && onReset();
  };

  const handleClick = (e: any) => {
    if (onClick) {
      return onClick();
    }
    if (e.target.className.includes('reset-btn')) {
      resetFilter();
    } else {
      setIsFilterOpen(prevState => !prevState);
    }
  };

  return (
    <div
      className={[classes.container, styles.container].join(' ')}
      tabIndex={0}
      ref={dropdownRef}
    >
      <div className={styles.filterBox} onClick={handleClick}>
        <div className={styles.wrapper}>
          <span className={styles.label}>{label}:</span>
          <div>
            {!!value ? (
              <div className={styles.selectedFilterWrapper}>
                <span className={styles.filterValue}>{value}</span>
                <span className={[styles.closeBtn, 'reset-btn'].join(' ')}>
                  &#10005;
                </span>
              </div>
            ) : (
              <span className={styles.noFilterValue}>
                {staticValue || t('any')}
              </span>
            )}
          </div>
        </div>
        <span
          className={[styles.chevron, isFilterOpen && styles.rotated].join(' ')}
        >
          ▲
        </span>
      </div>
      {isFilterOpen && children && (
        <div
          className={[
            classes.filterDropdown,
            styles.filterDropdown,
            'opened-filter',
          ].join(' ')}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default FilterItem;
