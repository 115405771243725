import Carousel from 'components/Carousel';

import img1 from '../../assets/images/grafika-1.jpg';
import img2 from '../../assets/images/grafika-2.jpg';
import img3 from '../../assets/images/grafika-3.jpg';

const GraphicsGallery = () => {
  const visualizationImages = [img1, img2, img3];

  return (
    <div>
      <Carousel imagesUrls={visualizationImages} />
    </div>
  );
};

export default GraphicsGallery;
