import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useContext, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';

import { COMPARTMENT_NAMES } from 'constants/options';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import { Compartment } from 'types/Apartments';
import { CustomTheme } from 'types/Style';

import styles from './styles.module.scss';
import { Props } from './types';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  tilesTableWrapper: {
    '& tbody tr': {
      backgroundColor: `${theme.primary} !important`,
    },
  },
  rowSelected: {
    '& td': {
      backgroundColor: `${theme.primaryDark} !important`,
    },
  },
}));

const RoomsTable = ({
  data,
  selectedRoom,
  setSelectedRoom,
  tableHover,
}: Props) => {
  const { t } = useContext(TranslationContext);
  const { listType } = useContext(StyleContext);
  const { primaryColor, primaryColorDark } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor, primaryDark: primaryColorDark },
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const columnHelper = createColumnHelper<any>();

  const areTilesSelected = useMemo(() => listType === 'kafelki', []);

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      columnHelper.accessor('name', {
        header: () => <span>{t('compartment')}</span>,
        cell: table => {
          const name: string = table.row.original.name;
          const compNames: { [k: string]: string } = COMPARTMENT_NAMES(t);
          return <span className={styles.compName}>{compNames[name]}</span>;
        },
      }),
      columnHelper.accessor('area', {
        header: () => <span>{t('areaShorthand')}</span>,
        cell: table => (
          <span>
            {table.row.original.area}m<sup>2</sup>
          </span>
        ),
      }),
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const getSortIcon = (sortState: string | boolean) => {
    if (typeof sortState === 'string') {
      return (
        <span className={styles.sortIcon}>
          {sortState === 'asc' ? '▲' : '▼'}
        </span>
      );
    }

    return null;
  };

  const handleRowClick = (row: Row<Compartment>) => {
    if (row.id === selectedRoom?.id) {
      setSelectedRoom(null);
      return;
    }
    const room: Compartment = { ...row.original, id: row.id };
    setSelectedRoom(room);
  };

  const getRowStyle = (row: Row<any>) => {
    const rowStyles = [styles.row];

    if (row.id === selectedRoom?.id) {
      rowStyles.push([classes.rowSelected, styles.rowSelected].join(' '));
    }

    if (row.id === tableHover) {
      rowStyles.push(styles.rowHovered);
    }

    return rowStyles.join(' ');
  };

  return (
    <div>
      <div
        className={[
          classes.tilesTableWrapper,
          styles.tableWrapper,
          areTilesSelected && styles.tilesTableWrapper,
        ].join(' ')}
      >
        <table className={styles.container} cellSpacing="0" cellPadding="0">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        className={[
                          styles.headerWrapper,
                          typeof header.column.getIsSorted() === 'string'
                            ? styles.highlighted
                            : '',
                        ].join(' ')}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {getSortIcon(header.column.getIsSorted())}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr
                key={row.id}
                className={getRowStyle(row)}
                onClick={() => handleRowClick(row)}
              >
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RoomsTable;
