import { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Carousel } from 'react-responsive-carousel';

import { ReactComponent as HideIcon } from 'assets/icons/hide.svg';
import { ReactComponent as LevelsIcon } from 'assets/icons/levels.svg';
import { ReactComponent as LocalsIcon } from 'assets/icons/locals.svg';
import { ReactComponent as Logo } from 'assets/icons/logo_aplus.svg';
import CompassRose from 'assets/icons/north.svg';
import OverlayControl from 'components/OverlayControl';
import RotateControl from 'components/RotateControl';
import { TranslationContext } from 'context/translationContext';
import { ReactComponent as Apartments } from 'data/project/apartments.svg';
import { ReactComponent as Tiers } from 'data/project/tiers.svg';

import styles from './styles.module.scss';
import { Props } from './types';

const Visualization = ({
  isOverlayVisible,
  selectedOverlay,
  currentImg,
  images,
  toggleOverlayVisbility,
  handlePrevClick,
  handleNextClick,
  handleOverlayChange,
  compassAngle,
}: Props) => {
  const { t } = useContext(TranslationContext);
  const [isDragging, setIsDragging] = useState(false);
  const [pos, setPos] = useState(0);
  const [mobileIncrement, setMobileIncrement] = useState(0);
  const [desktopIncrement, setDesktopIncrement] = useState(0);

  const getIcon = () => {
    if (isOverlayVisible) {
      return <HideIcon />;
    }
    return selectedOverlay === 'floors' ? <LevelsIcon /> : <LocalsIcon />;
  };

  const handleMove = (e: any) => {
    if (isDragging && !isMobile) {
      if (desktopIncrement >= 3) {
        if (e.pageX < pos) {
          handleNextClick();
        } else if (e.pageX > pos) {
          handlePrevClick();
        }
        setPos(e.pageX);
        setDesktopIncrement(0);
      } else {
        setDesktopIncrement(prevValue => prevValue + 1);
      }
    }
  };

  const handleMobileMove = (e: any) => {
    if (isDragging && isMobile) {
      if (mobileIncrement >= 0) {
        if (e.screenX < pos) {
          handleNextClick();
        } else if (e.screenX > pos) {
          handlePrevClick();
        }
        setPos(e.screenX);
        setMobileIncrement(0);
      } else {
        setMobileIncrement(prevValue => prevValue + 1);
      }
    }
  };

  const handleMouseDown = (e: any) => {
    setPos(e.pageX);
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div
      className={styles.viewWrapper}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMove}
      onMouseUp={handleMouseUp}
      onPointerDown={handleMouseDown}
      onPointerMove={handleMobileMove}
      onPointerUp={handleMouseUp}
    >
      <Carousel
        selectedItem={currentImg}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        showArrows={false}
        swipeable={false}
        infiniteLoop
      >
        {images?.map(media => (
          <div key={media} className={styles.imageWrapper}>
            <img src={media} className={styles.image} />
          </div>
        ))}
      </Carousel>
      {selectedOverlay === 'floors' ? (
        <Tiers id="overlay" className={styles.svgImage} />
      ) : (
        <Apartments
          id="overlay"
          className={[styles.svgImage, 'apartmentsWithOverlay'].join(' ')}
        />
      )}
      <button
        className={styles.overlayVisibilityBtn}
        onClick={toggleOverlayVisbility}
        data-tip={isOverlayVisible ? t('hideOutline') : t('showOutline')}
      >
        {getIcon()}
      </button>
      <RotateControl
        onPrevClick={handlePrevClick}
        onNextClick={handleNextClick}
      />
      <OverlayControl
        selected={selectedOverlay}
        onChange={handleOverlayChange}
        isOverlayVisible={isOverlayVisible}
      />
      <div className={styles.logoWrapper}>
        <a href="https://cadaplus.com/BIM+/" target="_blank">
          <Logo />
        </a>
      </div>
      <img
        src={CompassRose}
        className={styles.compassRose}
        style={{
          transform: `rotateX(60deg) rotateZ(${compassAngle}deg)`,
        }}
      />
    </div>
  );
};

export default Visualization;
