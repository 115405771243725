import { CompartmentsTheme, RoomTheme } from 'context/styleContext';

export const ROOMS_COLORS_DESCRIPTION = (
  t: (id: string) => string,
  roomAmountColors: RoomTheme,
) => {
  return [
    {
      id: '1',
      color: roomAmountColors.room1,
      label: t('1room'),
    },
    {
      id: '2',
      color: roomAmountColors.room2,
      label: t('2room'),
    },
    {
      id: '3',
      color: roomAmountColors.room3,
      label: t('3room'),
    },
    {
      id: '4',
      color: roomAmountColors.room4,
      label: t('4room'),
    },
    {
      id: '5',
      color: roomAmountColors.room5OrMore,
      label: t('5OrMoreRoom'),
    },
  ];
};

export const COMPARTMENTS_COLORS_DESCRIPTION = (
  t: (id: string) => string,
  compartmentsColors: CompartmentsTheme,
) => {
  return [
    {
      id: '1',
      color: compartmentsColors.livingRoom,
      label: t('livingRoom'),
    },
    {
      id: '2',
      color: compartmentsColors.kitchen,
      label: t('kitchen'),
    },
    {
      id: '3',
      color: compartmentsColors.bathroom,
      label: t('bathroom'),
    },
    {
      id: '4',
      color: compartmentsColors.bedroom,
      label: t('bedroom'),
    },
    {
      id: '5',
      color: compartmentsColors.terrace,
      label: t('terrace'),
    },
    {
      id: '6',
      color: compartmentsColors.wc,
      label: t('wc'),
    },
  ];
};
