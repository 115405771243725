import Carousel from 'components/Carousel';

import img1 from '../../assets/images/3d-1.jpg';
import img2 from '../../assets/images/3d-2.png';
import img3 from '../../assets/images/3d-3.png';

const Scan3DGallery = () => {
  const visualizationImages = [img1, img2, img3];

  return (
    <div>
      <Carousel imagesUrls={visualizationImages} />
    </div>
  );
};

export default Scan3DGallery;
