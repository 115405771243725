import { useContext } from 'react';

import ApartamentsTable from 'components/ApartamentsTable';
import ApartmentDescription from 'components/ApartmentDescription';
import ApartmentView from 'components/ApartmentView';
import CardDescription from 'components/CardDescription';
import CardGallery from 'components/CardGallery';
import ContactSection from 'components/ContactSection';
import FloorsDescription from 'components/FloorsDescription';
import FloorsView from 'components/FloorsView';
import Footer from 'components/Footer';
import GalleryDescription from 'components/GalleryDescription';
import GalleryView from 'components/GalleryView';
import GraphicsDescription from 'components/GraphicsDescription';
import GraphicsGallery from 'components/GraphicsGallery';
import Header from 'components/Header';
import Hero from 'components/Hero';
import InvestmentAnalyzerDescription from 'components/InvestmentAnalyzerDescription';
import InvestmentAnalyzerGallery from 'components/InvestmentAnalyzerGallery';
import Loader from 'components/Loader';
import LogosDescription from 'components/LogosDescription';
import LogosGallery from 'components/LogosGallery';
import MapDescription from 'components/MapDescription';
import MapView from 'components/MapView';
import PartnerDescription from 'components/PartnerDescription';
import PartnerGallery from 'components/PartnerGallery';
import Scan3DDescription from 'components/Scan3DDescription';
import Scan3DGallery from 'components/Scan3DGallery';
import Section from 'components/Section';
import Section2 from 'components/Section2';
import TableDescription from 'components/TableDescription';
import ThreeDView from 'components/ThreeDView';
import ThreeDViewDescription from 'components/ThreeDViewDescription';
import VisualizationDescription from 'components/VisualizationDescription';
import VisualizationGallery from 'components/VisualizationGallery';
import VtripDescription from 'components/VtripDescription';
import VtripIframe from 'components/VtripIframe';
import WebsitesDescription from 'components/WebsitesDescription';
import WebsitesGallery from 'components/WebsitesGallery';
import { DataContext } from 'context/dataContext';

import styles from './styles.module.scss';

const Layout = () => {
  const { apartments, isFetchingApartments } = useContext(DataContext);

  if (isFetchingApartments) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      {/* zawinac */}
      <Header />
      <Hero />
      <Section
        id="threeDSection"
        label="Makieta+"
        sublabel=" interaktywna Makieta 3D."
        children1={<ThreeDView />}
        children2={<ThreeDViewDescription />}
      />
      <Section
        id="tableSection"
        label="Wyszukiwarka+"
        isInverted
        children1={<ApartamentsTable data={apartments} />}
        children2={<TableDescription />}
      />
      <Section
        id="gallerySection"
        label="Galeria+"
        sublabel="z dodatkowymi funkcjami bezpośrednio na wizualizacjach klienta."
        children1={<GalleryView />}
        children2={<GalleryDescription />}
      />
      <Section
        id="floorsSection"
        label="Kondygnacje+"
        sublabel="Interaktywne rzuty kondygnacji"
        isInverted
        children1={<FloorsView />}
        children2={<FloorsDescription />}
      />
      <Section
        id="apartmentSection"
        label="Karta+"
        sublabel="wirtualna karta katalogowa"
        children1={<ApartmentView />}
        children2={<ApartmentDescription />}
      />
      <Section
        id="mapSection"
        label="Mapy+"
        isInverted
        children1={<MapView />}
        children2={<MapDescription />}
      />
      <Section
        id="vtripSection"
        label="Mieszkanie+"
        sublabel="Spacer wirtualny po mieszkaniu"
        children1={<VtripIframe />}
        children2={<VtripDescription />}
      />
      <Section
        id="cartSection"
        label="Katalog+"
        sublabel="Karty katalogowe na wzorze klienta w formacie PDF"
        isInverted
        children1={<CardGallery />}
        children2={<CardDescription />}
      />
      <Section
        id="visualisationSection"
        label="Wizualizacje+"
        children1={<VisualizationGallery />}
        children2={<VisualizationDescription />}
      />
      <Section
        id="analyzerSection"
        label="Analizator inwestycji BIM+"
        isInverted
        children1={<InvestmentAnalyzerGallery />}
        children2={<InvestmentAnalyzerDescription />}
      />
      <Section
        id="pagesSection"
        label="Strona internetowa inwestycji (WWW+)"
        isInverted
        children1={<WebsitesGallery />}
        children2={<WebsitesDescription />}
      />
      <Section
        id="graphicsSection"
        label="Grafika+"
        children1={<GraphicsGallery />}
        children2={<GraphicsDescription />}
      />
      <Section
        id="scan3DSection"
        label="Skanowanie 3D (Scan 3D+)"
        isInverted
        children1={<Scan3DGallery />}
        children2={<Scan3DDescription />}
      />
      <Section
        id="partnerSection"
        label="Partner strategiczny"
        children1={<PartnerGallery />}
        children2={<PartnerDescription />}
      />
      <Section2
        id="logosSection"
        label="Klienci"
        children1={<LogosGallery />}
        children2={<LogosDescription />}
      />
      <ContactSection />
      <Footer />
    </div>
  );
};

export default Layout;
