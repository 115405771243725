import { MenuIcon, ViewGridIcon } from '@heroicons/react/outline';
import { useContext, useEffect, useMemo, useState } from 'react';

import FilterItem from 'components/FilterItem';
import FiltersModal from 'components/FiltersModal';
import InputRange from 'components/InputRange';
import InputRangeButton from 'components/InputRangeButton';
import { ApartmentsFilterContext } from 'context/apartmentsFilterContext';
import { DataContext } from 'context/dataContext';
import { TranslationContext } from 'context/translationContext';
import { ListType } from 'types/Style';

import styles from './styles.module.scss';
import { Props } from './types';

const Filters = ({ table, onViewChange, currentView }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setFilterValue, filterValues, handleFilterChange, resetFilters } =
    useContext(ApartmentsFilterContext);
  const { areaOptions, floorOptions } = useContext(DataContext);
  const { t } = useContext(TranslationContext);

  const handleModalOpen = () => {
    setIsModalOpen(true);
    window.parent.postMessage(['setPos'], '*');
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const setFilterForColumn = (column: string, value: any) =>
    table.getColumn(column).setFilterValue(value);

  const { disabledFilters } = useMemo(() => {
    const totalRows = table.getCoreRowModel().rows;
    const maxAmountOfRooms = Math.max(...totalRows.map(x => x.original.rooms));
    const disabledFilters = {
      price: !totalRows.some(x => x.original.price),
      pricePerMeter: !totalRows.some(x => x.original.price && x.original.area),
    };

    return { maxAmountOfRooms, disabledFilters, totalRows };
  }, []);

  const filteredRows = Object.keys(table.getRowModel().rowsById);

  const {
    roomMax,
    roomMin,
    areaMin,
    areaMax,
    externalArea,
    priceMin,
    priceMax,
    pricePerMeterMin,
    pricePerMeterMax,
    floorMin,
    floorMax,
    kitchenType,
    flatPlacement,
    directions,
  } = filterValues;

  useEffect(() => {
    setFilterForColumn('rooms', [roomMin, roomMax]);
  }, [roomMin, roomMax]);

  useEffect(() => {
    setFilterForColumn('area', [areaMin, areaMax]);
  }, [areaMin, areaMax]);

  useEffect(() => {
    setFilterForColumn('balconyOrTerrace', externalArea);
  }, [externalArea]);

  useEffect(() => {
    setFilterForColumn('price', [priceMin, priceMax]);
  }, [priceMin, priceMax]);

  useEffect(() => {
    setFilterForColumn('pricePerMeter', [pricePerMeterMin, pricePerMeterMax]);
  }, [pricePerMeterMin, pricePerMeterMax]);

  useEffect(() => {
    setFilterForColumn('floor', [floorMin, floorMax]);
  }, [floorMin, floorMax]);

  useEffect(() => {
    setFilterForColumn('kitchen', kitchenType);
  }, [kitchenType]);

  useEffect(() => {
    setFilterForColumn('flatPlacement', flatPlacement);
  }, [flatPlacement]);

  useEffect(() => {
    setFilterForColumn('directions', directions);
  }, [directions]);

  const handleViewTypeClick = (type: ListType) => {
    onViewChange(type);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <FilterItem
          label={t('rooms')}
          value={
            roomMin && roomMax
              ? roomMin === roomMax
                ? `${roomMin}`
                : `${roomMin} - ${roomMax}`
              : ''
          }
          onReset={() => {
            setFilterValue('roomMin', '');
            setFilterValue('roomMax', '');
          }}
        >
          <InputRangeButton
            minValue={roomMin}
            maxValue={roomMax}
            minValueKey={'roomMin'}
            maxValueKey={'roomMax'}
            handleChange={setFilterValue}
          />
        </FilterItem>
        <FilterItem
          label={t('area')}
          value={
            areaMin
              ? areaMax
                ? `${areaMin} - ${areaMax}`
                : `${t('from')} ${areaMin}`
              : areaMax
              ? `${t('to')} ${areaMax}`
              : ''
          }
          onReset={() => {
            setFilterValue('areaMin', '');
            setFilterValue('areaMax', '');
          }}
        >
          <InputRange
            smallInput
            minValue={areaMin}
            maxValue={areaMax}
            minValueKey="areaMin"
            maxValueKey="areaMax"
            minValueOptions={areaOptions}
            maxValueOptions={areaOptions}
            onChange={handleFilterChange}
            minPlaceholder={t('from')}
            maxPlaceholder={t('to')}
          />
        </FilterItem>
        <FilterItem
          label={t('floor')}
          value={
            floorMin
              ? floorMax
                ? `${floorMin} - ${floorMax}`
                : `${t('from')} ${floorMin}`
              : floorMax
              ? `${t('to')} ${floorMax}`
              : ''
          }
          onReset={() => {
            setFilterValue('floorMin', '');
            setFilterValue('floorMax', '');
          }}
        >
          <InputRange
            smallInput
            minValue={floorMin}
            maxValue={floorMax}
            minValueKey="floorMin"
            maxValueKey="floorMax"
            minValueOptions={floorOptions}
            maxValueOptions={floorOptions}
            onChange={handleFilterChange}
            minPlaceholder={t('from')}
            maxPlaceholder={t('to')}
          />
        </FilterItem>
        <FilterItem
          label={t('more')}
          value=""
          staticValue={t('pick')}
          onClick={handleModalOpen}
        />
      </div>
      <div className={styles.bottomWrapper}>
        <div className={styles.viewWrapper}>
          <span className={styles.viewLabel}>{`${t('view')}:`}</span>
          <button
            className={styles.iconWrapper}
            onClick={() => handleViewTypeClick('tabela')}
          >
            <MenuIcon
              className={[
                styles.icon,
                currentView === 'tabela' && styles.activeIcon,
              ].join(' ')}
            />
          </button>
          <button
            className={styles.iconWrapper}
            onClick={() => handleViewTypeClick('kafelki')}
          >
            <ViewGridIcon
              className={[
                styles.icon,
                currentView === 'kafelki' && styles.activeIcon,
              ].join(' ')}
            />
          </button>
        </div>
        <p className={styles.amountLabel}>
          <a className={styles.resetBtn} onClick={resetFilters}>
            {t('removeFilters')}
          </a>
          {' | '}
          {t('flatsAmount')}: <strong>{filteredRows.length}</strong>
        </p>
      </div>
      {isModalOpen && (
        <FiltersModal
          handleClose={handleModalClose}
          values={filterValues}
          onChange={handleFilterChange}
          onSetFieldValue={setFilterValue}
          disabledFilters={disabledFilters}
        />
      )}
    </>
  );
};

export default Filters;
