import axios from 'axios';

import { config } from 'config';
import { StyleResponse } from 'types/Style';

export const getStyleData = async (): Promise<StyleResponse | null> => {
  try {
    const url = `${config.strapiUrl}/api/style-config`;
    const response = await axios.get<StyleResponse>(url);
    return response.data;
  } catch (err) {
    console.error('[Strapi API failed]', err);
    return null;
  }
};
