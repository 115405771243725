import { useContext, useState } from 'react';

import ShowMore from 'components/ShowMore';
import { TranslationContext } from 'context/translationContext';

const GraphicsDescription = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useContext(TranslationContext);

  const toggleOpen = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div>
      <span>{t('graphicsClaim')}</span>
      <br />
      <br />
      <ul>
        <li>{t('graphicsAdvantage1')}</li>
        <li>{t('graphicsAdvantage2')}</li>
        <li>{t('graphicsAdvantage3')}</li>
      </ul>
      {isOpen && (
        <>
          <br />
          <p>{t('graphicsDescription1')}</p>
          <br />
        </>
      )}
      <ShowMore isOpen={isOpen} handleClick={toggleOpen} />
    </div>
  );
};

export default GraphicsDescription;
