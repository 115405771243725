import styles from './styles.module.scss';
import { Props } from './types';

const RadioSelect = ({ name, value, options, onSetFieldValue }: Props) => {
  const handleChange = (newValue: string) => {
    if (newValue === value) {
      return onSetFieldValue(name, '');
    }
    onSetFieldValue(name, newValue);
  };

  return (
    <div>
      {options.map(option => (
        <div key={option.value} className={styles.container}>
          <input
            type="radio"
            name={name}
            onChange={() => handleChange(option.value)}
            checked={option.value === value}
            className={styles.input}
          />
          <span
            onClick={() => handleChange(option.value)}
            className={styles.label}
          >
            {option.label}
          </span>
        </div>
      ))}
    </div>
  );
};

export default RadioSelect;
