import { useContext } from 'react';
import { createUseStyles } from 'react-jss';

import { StyleContext } from 'context/styleContext';
import { CustomTheme } from 'types/Style';

import styles from './styles.module.scss';
import { Props } from './types';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  input: {
    outlineColor: theme.primary,
    color: theme.primary,
  },
}));

const InputRange = ({
  smallInput,
  minValue,
  maxValue,
  minValueKey,
  maxValueKey,
  minValueOptions,
  maxValueOptions,
  minPlaceholder,
  maxPlaceholder,
  onChange,
  disabled,
}: Props) => {
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });
  // TODO: Handle max value lower than min value and vice versa

  return (
    <div className={styles.container}>
      <select
        disabled={disabled}
        className={
          smallInput
            ? [styles.input, styles.smallInput].join(' ')
            : styles.input
        }
        id={minValueKey}
        value={minValue}
        onChange={onChange}
        placeholder={minPlaceholder}
      >
        <option disabled value="">
          Od
        </option>
        {minValueOptions &&
          minValueOptions.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
      </select>
      <span className={styles.separator}>-</span>
      <select
        disabled={disabled}
        className={
          smallInput
            ? [classes.input, styles.input, styles.smallInput].join(' ')
            : [classes.input, styles.input].join(' ')
        }
        id={maxValueKey}
        value={maxValue}
        onChange={onChange}
        placeholder={maxPlaceholder}
      >
        <option disabled value="">
          Do
        </option>
        {maxValueOptions &&
          maxValueOptions.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
      </select>
    </div>
  );
};

export default InputRange;
