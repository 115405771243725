import { useContext, useState } from 'react';

import { ReactComponent as Logo } from 'assets/icons/logo_aplus.svg';
import ShowMore from 'components/ShowMore';
import { TranslationContext } from 'context/translationContext';

import styles from './styles.module.scss';

const PartnerDescription = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useContext(TranslationContext);

  const toggleOpen = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div>
      <span>{t('partnerClaim')}</span>
      <br />
      <br />
      <ul>
        <li>{t('partnerAdvantage1')}</li>
        <li>
          {t('partnerAdvantage2')}{' '}
          <div className={styles.ourLogo}>
            <Logo />
          </div>
        </li>
        <li>{t('partnerAdvantage3')}</li>
      </ul>
      {isOpen && (
        <>
          <br />
          <p>{t('partnerDescription1')}</p>
          <br />
        </>
      )}
      <ShowMore isOpen={isOpen} handleClick={toggleOpen} />
    </div>
  );
};

export default PartnerDescription;
