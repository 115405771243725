import { importAllFiles } from 'utils/importAllFiles';
import { sortFilesWithNumbers } from 'utils/sortFilesWithNumbers';

export const importImagesFromProject = () => {
  const fetched = importAllFiles(
    require.context('data/project', false, /\.(png|jpe?g)$/),
  );
  // @ts-ignore
  const unique = [...new Set(fetched)];
  const sorted = sortFilesWithNumbers(unique);

  return sorted;
};
