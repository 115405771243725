const VtripIframe = () => {
  // eslint-disable-next-line no-use-before-define
  return (
    <div
      dangerouslySetInnerHTML={{
        __html:
          '<iframe title="Apartament pokazowy" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/a7b1e6cd8d8c4d03966c49c1511606d6/embed"> </iframe>',
      }}
    />
  );
};

export default VtripIframe;
