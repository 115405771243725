import { useContext, useState } from 'react';

import ShowMore from 'components/ShowMore';
import { TranslationContext } from 'context/translationContext';

const TableDescription = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useContext(TranslationContext);

  const toggleOpen = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div>
      <span>{t('tableClaim')}</span>
      <br />
      <br />
      <ul>
        <li>{t('tableAdvantage1')}</li>
        <li>{t('tableAdvantage2')}</li>
        <li>
          {t('tableAdvantage3')}
          <ul>
            <li>{t('tableAdvantage31')}</li>
            <li>{t('tableAdvantage32')}</li>
            <li>{t('tableAdvantage33')}</li>
          </ul>
        </li>
      </ul>
      {isOpen && (
        <>
          <br />
          <p>{t('tableDescription1')}</p>
        </>
      )}
      <ShowMore isOpen={isOpen} handleClick={toggleOpen} />
    </div>
  );
};

export default TableDescription;
