import * as Yup from 'yup';

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const phoneRegExp = /^(\+{1})?[0-9]+$/;

export const ContactSchema = (t: (id: string) => string) => {
  return Yup.object().shape({
    name: Yup.string().required(t('emptyField')),
    email: Yup.string()
      .matches(emailRegExp, t('incorrectEmail'))
      .required(t('emptyField')),
    phone: Yup.string()
      .matches(phoneRegExp, t('incorrectPhone'))
      .min(9, t('phoneTooShort'))
      .required(t('emptyField')),
    message: Yup.string().required(t('emptyField')),
    termsAccepted: Yup.boolean().oneOf([true], t('requiredField')),
    marketingTermsAccepted: Yup.boolean().oneOf([true], t('requiredField')),
  });
};
