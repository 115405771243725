import { GestureHandling } from 'leaflet-gesture-handling';
import { useState, useMemo, useContext, useEffect, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { createUseStyles } from 'react-jss';
import { ReactSVG } from 'react-svg';

import { ReactComponent as Logo } from 'assets/icons/logo_aplus.svg';
require(`data/edifices/${process.env.REACT_APP_BUILDING_1}/tiers/tier.css`);
import CompassRose from 'assets/icons/north.svg';
import FloorSwitcher from 'components/FloorSwitcher';
import Legend from 'components/Legend';
import Tooltip from 'components/Tooltip';
import { AVAILABLE } from 'constants/apartmentStatus';
import { ApartmentsFilterContext } from 'context/apartmentsFilterContext';
import { DataContext } from 'context/dataContext';
import { NavigationContext } from 'context/navigationContext';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import projectData from 'data/project.json';
import 'data/pzt.css';
import PZT from 'data/pzt.svg';
import { CustomTheme } from 'types/Style';
import { importAllFiles } from 'utils/importAllFiles';
import { scrollToSection } from 'utils/scrollToSection';

import styles from './styles.module.scss';

import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

const aEdifice = require(`data/edifices/${process.env.REACT_APP_BUILDING_1}/edifice.json`);
// Uncomment when two buildings
// const bEdifice = require(`data/edifices/${process.env.REACT_APP_BUILDING_2}/edifice.json`);

const PZT_VIEW_BOX_X = 1600;
const PZT_VIEW_BOX_Y = 1200;

const useStyles = createUseStyles((theme: CustomTheme) => ({
  wrapper: {
    border: `1px solid ${theme.primary}`,
  },
  sold: {
    color: theme.primary,
  },
}));

const FloorsView = () => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [ofertNo, setOfertNo] = useState('');
  const [roomsAmount, setRoomsAmount] = useState('');
  const [area, setArea] = useState('');
  const [isSold, setIsSold] = useState(false);
  const [currentFloor, setCurrentFloor] = useState(0);
  const [isPointingNorth, setIsPointingNorth] = useState(false);
  const [map, setMap] = useState<HTMLElement | null>(null);
  const [isMoved, setIsMoved] = useState(false);
  const { selectedFloor, setSelectedFloor, setSelectedAptId } =
    useContext(NavigationContext);
  const { t } = useContext(TranslationContext);
  const { filterValues } = useContext(ApartmentsFilterContext);
  const { apartments } = useContext(DataContext);
  const { isMapHidden } = useContext(StyleContext);
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });
  const { roomMin, roomMax } = filterValues;

  useEffect(() => {
    if (selectedFloor) {
      setCurrentFloor(selectedFloor);
    }
  }, [selectedFloor]);

  const position: any = useMemo(() => {
    const { address } = projectData;
    return [address.geo_cenlat, address.geo_cenlon];
  }, []);

  const bounds: any = useMemo(() => {
    const { address } = projectData;
    return [
      [address.geo_r1lat, address.geo_r1lon],
      [address.geo_r2lat, address.geo_r2lon],
    ];
  }, []);

  const rotateAngle = useMemo(
    () => projectData.address.north_ang,
    [projectData],
  );

  const floors = useMemo(() => {
    const parsedFloors = [];

    const builidingFloors = importAllFiles(
      require.context(
        `data/edifices/${process.env.REACT_APP_BUILDING_1}/tiers`,
        false,
        /\.(svg)$/,
      ),
    );

    // @ts-ignore
    const uniqueFloors = [...new Set(builidingFloors)];

    for (let i = 0; i < uniqueFloors.length; i++) {
      if (!!uniqueFloors[i]) {
        parsedFloors.push({
          url: uniqueFloors[i],
          scale: aEdifice.pzt_w,
          rotate: aEdifice.pzt_ang,
          translateX: parseFloat((PZT_VIEW_BOX_X * aEdifice.pzt_x).toFixed(4)),
          translateY: parseFloat((PZT_VIEW_BOX_Y * aEdifice.pzt_y).toFixed(4)),
        });
      }
    }

    return parsedFloors;
  }, []);

  const handleHigherFloorClick = () => {
    if (currentFloor + 1 < floors.length) {
      setCurrentFloor(prevState => prevState + 1);
    }
    const pzt = document.getElementById('FloorViewPZT');
    if (pzt != null) pzt.style.opacity = '0.2';
  };

  const handleLowerFloorClick = () => {
    const pzt = document.getElementById('FloorViewPZT');
    if (currentFloor !== 0) {
      setCurrentFloor(prevState => prevState - 1);
      if (currentFloor == 1) if (pzt != null) pzt.style.opacity = '1.0';
    }
  };

  const moveElements = () => {
    if (!isMoved) {
      const DOMFloors = document.getElementsByClassName('floor');
      const FloorSVGs = document.querySelectorAll('.floorSVG .injected-svg');

      if (DOMFloors.length !== 0 && FloorSVGs.length !== 0) {
        for (let i = 0; i < DOMFloors.length; i++) {
          DOMFloors[i].append(FloorSVGs[i]);
        }
      }
      setIsMoved(true);
    }
  };

  const fillTooltipValues = useCallback((e: any) => {
    const apartNo = e.target.getAttribute('data-ap');
    const id = e.target.getAttribute('toid');

    if (apartNo && id) {
      const aptData = apartments.find(apt => apt.id === id);

      if (aptData) {
        setIsSold(aptData.status !== AVAILABLE);
        setOfertNo(apartNo);
        setArea(aptData.area.toString());
        setRoomsAmount(aptData.rooms.toString());

        if (aptData.dontShow) {
          hideTooltip();
          e.target.style.fill = 'transparent';
        } else {
          openTooltip();
        }
      }
    }
  }, []);

  const openTooltip = () => {
    setIsTooltipOpen(true);
  };

  const hideTooltip = () => {
    setIsTooltipOpen(false);
    setIsSold(false);
  };

  const handleClick = useCallback((e: any) => {
    const id = e.target.getAttribute('toid');
    if (id) {
      const aptData = apartments.find(apt => apt.id === id);
      if (
        aptData &&
        aptData.status === AVAILABLE &&
        aptData.dontShow === false
      ) {
        setSelectedAptId(id);
        scrollToSection('apartmentSection');
        if (aptData.compartments.length !== 0) {
          setSelectedFloor(aptData.compartments[0].ref_tier);
        }
      }
    }
  }, []);

  const addApartmentRoomsClasses = () => {
    const visibleFloor = document.getElementsByClassName('floor-visible');
    if (visibleFloor.length !== 0) {
      const flatsPath = visibleFloor[0].querySelectorAll('.rm');
      flatsPath.forEach(path => {
        const id = path.getAttribute('toid');
        const apartmentInfo = apartments.find(apt => apt.id === id);
        if (apartmentInfo) {
          if (apartmentInfo.status !== AVAILABLE || apartmentInfo.dontShow) {
            return;
          }

          const aptRooms = apartmentInfo.rooms;
          const parsedRoomMin = parseInt(roomMin);
          const parsedRoomMax = parseInt(roomMax);

          if (parsedRoomMin && parsedRoomMax) {
            if (aptRooms >= parsedRoomMin && aptRooms <= parsedRoomMax) {
              if (apartmentInfo.status === AVAILABLE) {
                path.classList.add(`room-${aptRooms}`);
              } else {
                path.classList.add('room-unavailable');
              }
            } else {
              if (apartmentInfo.status === AVAILABLE) {
                path.classList.remove(`room-${aptRooms}`);
              } else {
                path.classList.remove('room-unavailable');
              }
            }
          } else {
            if (apartmentInfo.status === AVAILABLE) {
              path.classList.add(`room-${aptRooms}`);
            } else {
              path.classList.add('room-unavailable');
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    addApartmentRoomsClasses();
  }, [roomMin, roomMax]);

  useEffect(() => {
    return () => {
      const DOMApartaments = document.getElementsByClassName('rm');

      for (let i = 0; i < DOMApartaments.length; i++) {
        if (!isMobile) {
          DOMApartaments[i].removeEventListener('mouseenter', openTooltip);
          DOMApartaments[i].removeEventListener(
            'mouseenter',
            fillTooltipValues,
          );
          DOMApartaments[i].removeEventListener('mouseleave', hideTooltip);
        }
        DOMApartaments[i].removeEventListener('click', handleClick);
      }
    };
  }, []);

  const addListeners = () => {
    const DOMApartaments = document.getElementsByClassName('rm');

    for (let i = 0; i < DOMApartaments.length; i++) {
      if (!isMobile) {
        DOMApartaments[i].addEventListener('mouseenter', openTooltip);
        DOMApartaments[i].addEventListener('mouseenter', fillTooltipValues);
        DOMApartaments[i].addEventListener('mouseleave', hideTooltip);
      }
      DOMApartaments[i].addEventListener('click', handleClick);
    }
  };

  const minZoomLevel = () => {
    var viewportWidth = window.innerWidth;
    var mapZoom;
    if (viewportWidth < 800) {
      mapZoom = 16;
    } else {
      mapZoom = 17.5;
    }
    return mapZoom;
  };

  const handleAfterInjection = (floorIdx: number) => {
    if (floorIdx + 1 === floors.length) {
      moveElements();
      addListeners();
      addApartmentRoomsClasses();
    }
  };

  useEffect(() => {
    const DOMFloors = document.getElementsByClassName('floor');

    if (DOMFloors.length !== 0) {
      for (let i = 0; i < DOMFloors.length; i++) {
        if (DOMFloors[i].classList.contains('floor-visible')) {
          DOMFloors[i].classList.remove('floor-visible');
          DOMFloors[i].classList.add('floorHidden');
        }
      }

      DOMFloors[currentFloor].classList.remove('floorHidden');
      DOMFloors[currentFloor].classList.add('floor-visible');
    }
  }, [currentFloor]);

  useEffect(() => {
    // @ts-ignore
    let container = L.DomUtil.get('floorMap');
    // @ts-ignore
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

    // @ts-ignore
    container = L.map('floorMap', {
      gestureHandling: true,
      center: position,
      minZoom: minZoomLevel(),
      zoom: minZoomLevel(),
      zoomSnap: 0.5,
      zoomDelta: 0.5,
      dragging: false,
      scrollWheelZoom: false,
      // @ts-ignore
      rotate: true,
      layers: [
        // @ts-ignore
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 26,
          scrollWheelZoom: false,
          maxNativeZoom: 25,
          zoomSnap: 0.5,
          zoomDelta: 0.5,
          dragging: false,
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }),
      ],
    });
    container.setBearing(-rotateAngle);

    const pztSvgElement = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg',
    );
    pztSvgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    pztSvgElement.setAttribute('x', '0');
    pztSvgElement.setAttribute('y', '0');
    pztSvgElement.setAttribute('viewBox', '0 0 1600 1200');
    pztSvgElement.setAttribute('preserveAspectRatio', 'xMidYMid slice');
    const svgElementOpener = `<g id="floor-pzt-wrapper" style="transform-origin: center center; transform: rotate(${rotateAngle}deg) scale(0.832322);">`;
    const svgElementClose = '</g>';
    let svgElementContent = '';
    // prettier-ignore
    floors.forEach((floor, idx) => {
      svgElementContent =
        svgElementContent +
        `<g style="transform: translate(${floor.translateX}px, ${floor.translateY
        }px) scale(${floor.scale}) rotate(${floor.rotate
        }deg);" class="apartmentsWithOverlay floor ${currentFloor !== idx ? 'floorHidden' : 'floor-visible'
        }"></g>`;
    });

    pztSvgElement.innerHTML =
      svgElementOpener + svgElementContent + svgElementClose;
    // @ts-ignore
    L.svgOverlay(pztSvgElement, bounds, {
      interactive: true,
      className: 'overlayVisible',
      // @ts-ignore
    }).addTo(container);
    setMap(container);
  }, []);

  const movePZT = () => {
    const pztWrapper = document.getElementById('floor-pzt-wrapper');
    const pzt = document.getElementById('FloorViewPZT');
    if (pztWrapper && pzt) {
      pztWrapper.insertBefore(pzt, pztWrapper.firstChild);
      pzt.classList.remove('floorHidden');
    }
  };

  const handleCompassClick = () => {
    setIsPointingNorth(prevState => !prevState);
  };

  useEffect(() => {
    if (map) {
      // @ts-ignore
      map.setBearing(isPointingNorth ? 0 : -rotateAngle);
    }
  }, [isPointingNorth]);

  useEffect(() => {
    if (map) {
      // @ts-ignore
      // prettier-ignore
      map.on('zoomend', function() {
        // @ts-ignore
        // prettier-ignore
        if(map.getZoom() != minZoomLevel()){
          // @ts-ignore
          map.dragging.enable();
        }
        else{
          // @ts-ignore
          map.dragging.disable();
          // @ts-ignore
          map.panTo(position);
        }
      });
    }
  });
  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftCol}>
          <div className={[classes.wrapper, styles.wrapper].join(' ')}>
            <div id="floorMap" className={isMapHidden ? 'mapHidden' : ''} />
            <ReactSVG
              src={PZT}
              wrapper="svg"
              id="FloorViewPZT"
              className="floorHidden"
              afterInjection={movePZT}
            />
            {floors.map((floor, idx) => {
              return (
                <ReactSVG
                  key={idx}
                  src={floor.url}
                  className={[styles.svgImage, 'floorSVG', 'floorHidden'].join(
                    ' ',
                  )}
                  wrapper="svg"
                  afterInjection={() => handleAfterInjection(idx)}
                />
              );
            })}
            <FloorSwitcher
              onLowerFloorClick={handleLowerFloorClick}
              onHigherFloorClick={handleHigherFloorClick}
              currentFloor={currentFloor}
            />
            <div className={styles.logoWrapper}>
              <Logo />
            </div>
            <ReactSVG
              src={CompassRose}
              wrapper="svg"
              id="compass"
              className={styles.compassRose}
              style={{
                transform: `rotate(${isPointingNorth ? 0 : -rotateAngle}deg)`,
              }}
              onClick={handleCompassClick}
            />
          </div>
          <Legend />
        </div>
      </div>
      {!isMobile && (
        <Tooltip id="floorTooltip" isOpen={isTooltipOpen}>
          <div className={styles.tooltip}>
            {isSold ? (
              <p className={[classes.sold, styles.sold].join(' ')}>
                {t('sold')}
              </p>
            ) : (
              <>
                <p>
                  {t('offerNumber')}: <b>{ofertNo}</b>
                </p>
                <p>
                  {t('roomsAmount')}: <b>{roomsAmount}</b>
                </p>
                <p>
                  {t('area')}:{' '}
                  <b>
                    {area}m<sup>2</sup>
                  </b>
                </p>
              </>
            )}
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default FloorsView;
