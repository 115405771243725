import axios from 'axios';

export const sendEmail = async (
  name: string,
  email: string,
  phone: string,
  content: string,
  utmCampaign: string,
  utmSource: string,
  utmMedium: string,
  utmTerm: string,
  utmContent: string,
  mailUrl: string,
  mailEndpoint: string,
  mailRecipient: string,
): Promise<any> => {
  const url = `${mailUrl}/${mailEndpoint}`;
  const response = await axios.post<any>(url, {
    name,
    email,
    phone,
    content,
    utmCampaign,
    utmSource,
    utmMedium,
    utmTerm,
    utmContent,
    recipientEmail: mailRecipient,
  });
  return response.data;
};
