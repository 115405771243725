import { useContext } from 'react';

import { TranslationContext } from 'context/translationContext';
import { scrollToSection } from 'utils/scrollToSection';

import background from '../../assets/images/background.jpeg';

import styles from './styles.module.scss';

const Hero = () => {
  const { t } = useContext(TranslationContext);

  return (
    <div id="start" className={styles.container}>
      <div className={styles.background}>
        <img src={background} alt="BIM" />
      </div>
      <div className={styles.content}>
        <h1 className={styles.label}>{t('label')}</h1>
        <p className={styles.sublabel}>{t('sublabel')}</p>
        <button
          className={styles.button}
          onClick={() => scrollToSection('threeDSection')}
        >
          {t('explorePossibilities')}
        </button>
      </div>
    </div>
  );
};

export default Hero;
