import { useContext } from 'react';
import { createUseStyles } from 'react-jss';

import { StyleContext } from 'context/styleContext';
import { CustomTheme } from 'types/Style';

import styles from './styles.module.scss';
import { Props } from './types';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  input: {
    outlineColor: theme.primary,
  },
  error: {
    color: theme.primary,
  },
}));

const Input = ({ label, value, placeholder, onChange, error }: Props) => {
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });

  return (
    <div className={styles.container}>
      <span className={styles.label}>{label}</span>
      <div className={styles.wrapper}>
        <input
          className={[classes.input, styles.input].join(' ')}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
      {error ? (
        <span className={classes.error}>{error}</span>
      ) : (
        <div className={styles.placeholder} />
      )}
    </div>
  );
};

export default Input;
