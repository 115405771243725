import { createContext, ReactNode, useState, useEffect } from 'react';

import { getContactData } from 'api/contact.api';
import ContactModal from 'components/ContactModal';
import { config } from 'config';

export interface ContactModalContext {
  openModal: (message: string) => void;
  closeModal: () => void;
  contactPhone: string;
  mailUrl: string;
  mailEndpoint: string;
  mailRecipient: string;
  privacyPolicyLink: string;
}

interface ContactModalContextProps {
  children: ReactNode;
}

export const ContactModalContext = createContext<ContactModalContext>({
  openModal: () => {},
  closeModal: () => {},
  contactPhone: config.phone || '',
  mailUrl: config.backendUrl || '',
  mailEndpoint: '',
  mailRecipient: '',
  privacyPolicyLink: '',
});

export const ContactModalProvider = ({
  children,
}: ContactModalContextProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contactModalMessage, setModalMessage] = useState('');
  const [contactPhone, setContactPhone] = useState(config.phone || '');
  const [mailUrl, setMailUrl] = useState(config.backendUrl || '');
  const [mailEndpoint, setMailEndpoint] = useState('');
  const [mailRecipient, setMailRecipient] = useState('');
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await getContactData();

      if (fetchedData) {
        const { data } = fetchedData;

        setContactPhone(data.attributes.Phone);
        setMailUrl(data.attributes.BEUrl);
        setMailEndpoint(data.attributes.BEEndpoint);
        setMailRecipient(data.attributes.RecipientEmail);
        setPrivacyPolicyLink(data.attributes.PrivacyPolicyLink);
      }
    };

    fetchData();
  }, []);

  const openModal = (message: string) => {
    setModalMessage(message);
    setIsModalOpen(true);
    window.parent.postMessage(['setPos'], '*');
  };

  const closeModal = () => {
    setModalMessage('');
    setIsModalOpen(false);
  };

  return (
    <ContactModalContext.Provider
      value={{
        openModal,
        closeModal,
        contactPhone,
        mailUrl,
        mailEndpoint,
        mailRecipient,
        privacyPolicyLink,
      }}
    >
      {children}
      {isModalOpen && <ContactModal message={contactModalMessage} />}
    </ContactModalContext.Provider>
  );
};
