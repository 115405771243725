import { useContext } from 'react';
import { createUseStyles } from 'react-jss';

import { StyleContext } from 'context/styleContext';
import { CustomTheme } from 'types/Style';

import styles from './styles.module.scss';
import { Props } from './types';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  textarea: {
    outlineColor: theme.primary,
  },
  error: {
    color: theme.primary,
  },
}));

const TextArea = ({ label, value, placeholder, onChange, error }: Props) => {
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });

  return (
    <div className={styles.container}>
      <span className={styles.label}>{label}</span>
      <textarea
        className={[classes.textarea, styles.textarea].join(' ')}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      {error ? (
        <span className={[classes.error, styles.error].join(' ')}>{error}</span>
      ) : (
        <div className={styles.placeholder} />
      )}
    </div>
  );
};

export default TextArea;
