import Carousel from 'components/Carousel';

import img1 from '../../assets/images/website1.jpg';
import img2 from '../../assets/images/website2.jpg';

const WebsitesGallery = () => {
  const websitesImages = [img1, img2];

  return (
    <div>
      <Carousel imagesUrls={websitesImages} />
    </div>
  );
};

export default WebsitesGallery;
