import { range, round } from 'lodash';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ReactSVG } from 'react-svg';

import { getApartmentsData } from 'api/apartments.api';
import { TranslationContext } from 'context/translationContext';
import Furnitures from 'data/furn.svg';
import { Apartment } from 'types/Apartments';
import { importAllFiles } from 'utils/importAllFiles';
import { importProjectData } from 'utils/importProjectData';
import { parseApartmentsToFloors } from 'utils/parseApartmentsToFloors';
require(`data/edifices/${process.env.REACT_APP_BUILDING_1}/apartments/aprt.css`);

export interface DataContext {
  apartments: Apartment[];
  areaOptions: string[];
  apartmentsPerFloorAmount: any;
  floorOptions: string[];
  roomsOptions: number[];
  projectData: any;
  apartmentsImages: any[];
  isFetchingApartments: boolean;
}

interface DataContextProps {
  children: ReactNode;
}

export const DataContext = createContext<DataContext>({
  apartments: [],
  areaOptions: [],
  apartmentsPerFloorAmount: {},
  floorOptions: [],
  roomsOptions: [],
  projectData: {},
  apartmentsImages: [],
  isFetchingApartments: true,
});

export const DataProvider = ({ children }: DataContextProps) => {
  const [apartments, setApartments] = useState<Apartment[]>([]);
  const [isFetchingApartments, setIsFetchingApartments] = useState(true);
  const { t } = useContext(TranslationContext);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getApartmentsData();

      if (data) {
        const parsed: Apartment[] = [];
        data.data.map(aprt => {
          const { attributes } = aprt;
          const compartments = attributes.Compartments.map(cprt => ({
            name: cprt.Name,
            area: cprt.Area,
            ref_tier: cprt.RefTier,
            window_area: cprt.WindowArea,
            perimeter: cprt.Perimeter,
            exposition: cprt.Exposition,
          }));

          parsed.push({
            address_part: attributes.AddressPart,
            area: attributes.Area,
            area_walls: attributes.AreaWalls,
            azimuth: attributes.Azimuth,
            compartments,
            exposition: attributes.Exposition,
            gate: attributes.Gate,
            id: attributes.DataID,
            insolation: attributes.Insolation,
            levels: attributes.Levels,
            number: attributes.Number,
            ordinal: attributes.Ordinal,
            regularity: attributes.Regularity,
            rooms: attributes.Rooms,
            type: attributes.Type,
            terraces: attributes.Terraces,
            building: attributes.Building,
            status: attributes.Status,
            dontShow: attributes.DontShow,
          });
        });

        setApartments(parsed);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (apartments.length !== 0) {
      setIsFetchingApartments(false);
    }
  }, [apartments]);

  const areaOptions = useMemo(() => {
    const areas = apartments.map(a => a.area);
    const smallestArea = Math.min(...areas);
    const roundedSmallest = round(smallestArea, -1);
    const biggestArea = Math.max(...areas);
    const roundedBiggest = round(biggestArea, -1);
    const step = 10;

    const numberRange = range(roundedSmallest, roundedBiggest, step);
    numberRange.push(roundedBiggest);
    const stringRange = numberRange.map(String);

    return stringRange;
  }, [apartments]);

  const apartmentsPerFloorAmount = useMemo(
    () => parseApartmentsToFloors(apartments),
    [apartments],
  );

  const floorOptions = useMemo(() => {
    const parsedFloors = Object.keys(apartmentsPerFloorAmount);

    if (parsedFloors.length !== 0) {
      parsedFloors.splice(0, 1, t('groundFloor'));
    }

    return parsedFloors;
  }, [apartmentsPerFloorAmount]);

  const roomsOptions = useMemo(() => {
    const rooms = apartments.map(a => a.rooms);
    const minRooms = Math.min(...rooms);
    const maxRooms = Math.max(...rooms);
    const step = 1;

    const parsedRooms = range(minRooms, maxRooms, step);
    parsedRooms.push(maxRooms);

    return parsedRooms;
  }, [apartments]);

  const projectData = useMemo(importProjectData, []);

  const apartmentsImages = useMemo(() => {
    const aBuilidingImages = importAllFiles(
      require.context(
        `data/edifices/${process.env.REACT_APP_BUILDING_1}/apartments`,
        false,
        /\.(svg)$/,
      ),
    );

    // Uncomment if two buildings
    // const bBuilidingImages = importAllFiles(
    //   require.context(`data/edifices/${process.env.REACT_APP_BUILDING_2}/apartments`, false, /\.(svg)$/),
    // );

    // @ts-ignore
    const uniqueAImages = [...new Set(aBuilidingImages)];
    // Uncomment if two buildings
    // @ts-ignore
    // const uniqueBImages = [...new Set(bBuilidingImages)];

    return [
      ...uniqueAImages,
      // Uncomment if two buildings
      // ...uniqueBImages,
    ];
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const height = document.getElementById('root')?.scrollHeight;
      window.parent.postMessage(['setHeight', height], '*');
    }, 100);
  }, []);

  return (
    <DataContext.Provider
      value={{
        apartments,
        areaOptions,
        apartmentsPerFloorAmount,
        floorOptions,
        roomsOptions,
        projectData,
        apartmentsImages,
        isFetchingApartments,
      }}
    >
      {children}
      <ReactSVG
        src={Furnitures}
        renumerateIRIElements={false}
        style={{ display: 'none' }}
      />
    </DataContext.Provider>
  );
};
