import { useContext, useState } from 'react';

import ShowMore from 'components/ShowMore';
import { TranslationContext } from 'context/translationContext';

const LogosDescription = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useContext(TranslationContext);

  const toggleOpen = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div>
      <span>{t('LogosClaim')}</span>
    </div>
  );
};

export default LogosDescription;
