import { useContext, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as LevelsIcon } from 'assets/icons/levels.svg';
import { ReactComponent as LocalsIcon } from 'assets/icons/locals.svg';
import { THREE_D_OVERLAY_OPTIONS } from 'constants/options';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import { CustomTheme } from 'types/Style';

import styles from './styles.module.scss';
import { Props } from './types';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  container: {
    backgroundColor: theme.primary,
  },
  label: {
    backgroundColor: theme.primary,
  },
}));

const OverlayControl = ({ selected, onChange, isOverlayVisible }: Props) => {
  const { t } = useContext(TranslationContext);
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({ theme: { primary: primaryColor } });

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const handleChange = (newValue: string) => {
    if (newValue !== selected) {
      onChange('selectedOverlay', newValue);
    }
  };

  return (
    <div
      className={[
        classes.container,
        styles.container,
        !isOverlayVisible && styles.hidden,
      ].join(' ')}
    >
      {THREE_D_OVERLAY_OPTIONS(t).map(option => (
        <div key={option.value} className={styles.wrapper}>
          <button
            onClick={() => handleChange(option.value)}
            className={[
              classes.label,
              styles.label,
              option.value !== selected && styles.blurred,
            ].join(' ')}
            data-tip={option.label}
          >
            {option.label === 'Mieszkania' ? <LocalsIcon /> : <LevelsIcon />}
          </button>
        </div>
      ))}
    </div>
  );
};

export default OverlayControl;
