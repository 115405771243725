import { GestureHandling } from 'leaflet-gesture-handling';
import { useMemo, useState, useEffect, useCallback, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { createUseStyles } from 'react-jss';
import { ReactSVG } from 'react-svg';

import { ReactComponent as Logo } from 'assets/icons/logo_aplus.svg';
import Tooltip from 'components/Tooltip';
import { DataContext } from 'context/dataContext';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import projectData from 'data/project.json';
import 'data/pzt2.css';
import PZT from 'data/pzt2.svg';
import { CustomTheme } from 'types/Style';
import { scrollToSection } from 'utils/scrollToSection';

import styles from './styles.module.scss';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  container: {
    border: `1px solid ${theme.primary}`,
  },
}));

const MapView = () => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [building, setBuilding] = useState('');
  const [flatsAmount, setFlatsAmount] = useState('');
  const [flatsAreaRange, setFlatsAreaRange] = useState('');
  const [flatsRooms, setFlatsRooms] = useState('');
  const [map, setMap] = useState<HTMLElement | null>(null);
  const { t } = useContext(TranslationContext);
  const { apartments } = useContext(DataContext);
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });

  const position: any = useMemo(() => {
    const { address } = projectData;
    return [address.geo_cenlat, address.geo_cenlon];
  }, []);

  const bounds: any = useMemo(() => {
    const { address } = projectData;
    return [
      [address.geo_r1lat, address.geo_r1lon],
      [address.geo_r2lat, address.geo_r2lon],
    ];
  }, []);

  useEffect(() => {
    return () => removeListeners();
  }, []);

  const getDataFromEdifice = (newBuildingName: string) => {
    const buildingApartments = apartments.filter(
      aprt => aprt.building === newBuildingName,
    );

    let lowestArea = buildingApartments[0].area;
    let highestArea = buildingApartments[0].area;
    let lowestNoOfRooms = buildingApartments[0].rooms;
    let highestNoOfRooms = buildingApartments[0].rooms;

    buildingApartments.forEach(apartment => {
      const { area, rooms } = apartment;

      if (area < lowestArea) {
        lowestArea = apartment.area;
      }

      if (area > highestArea) {
        highestArea = apartment.area;
      }

      if (rooms < lowestNoOfRooms) {
        lowestNoOfRooms = rooms;
      }

      if (rooms > highestNoOfRooms) {
        highestNoOfRooms = rooms;
      }
    });

    setFlatsAmount(buildingApartments.length.toString());
    setFlatsAreaRange(`${lowestArea} - ${highestArea}`);
    setFlatsRooms(`${lowestNoOfRooms} - ${highestNoOfRooms}`);
  };

  useEffect(() => {
    if (building) {
      getDataFromEdifice(building);
    }
  }, [building]);

  const openTooltip = useCallback((e: any) => {
    const targetBuilding = e.target.getAttribute('building');
    if (targetBuilding) {
      setBuilding(targetBuilding);
      setIsTooltipOpen(true);
    }
  }, []);

  const hideTooltip = useCallback(() => {
    setIsTooltipOpen(false);
  }, []);

  const navigateToThreeD = useCallback(() => {
    scrollToSection('threeDSection');
  }, []);

  const addListeners = () => {
    const pzt = document.getElementById('PZT');
    const pztFloor = pzt?.getElementsByClassName('bu');
    if (pztFloor && pztFloor.length !== 0) {
      if (!isMobile) {
        pztFloor[0].addEventListener('mouseenter', openTooltip);
        pztFloor[0].addEventListener('mouseleave', hideTooltip);
      }
      pztFloor[0].addEventListener('click', navigateToThreeD);
    }
  };

  const removeListeners = () => {
    const pzt = document.getElementById('PZT');
    const pztFloor = pzt?.getElementsByClassName('bu');
    if (pztFloor && pztFloor.length !== 0) {
      if (!isMobile) {
        pztFloor[0].removeEventListener('mouseenter', openTooltip);
        pztFloor[0].removeEventListener('mouseleave', hideTooltip);
      }
      pztFloor[0].removeEventListener('click', navigateToThreeD);
    }
  };

  useEffect(() => {
    // @ts-ignore
    let container = L.DomUtil.get('map');
    // @ts-ignore
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
    // @ts-ignore
    container = L.map('map', {
      gestureHandling: true,
      center: position,
      scrollWheelZoom: false,
      zoom: 16.5,
      zoomSnap: 0.5,
      layers: [
        // @ts-ignore
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 26,
          scrollWheelZoom: false,
          maxNativeZoom: 25,
        }),
      ],
    });

    const pztSvgElement = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg',
    );
    pztSvgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    pztSvgElement.setAttribute('x', '0');
    pztSvgElement.setAttribute('y', '0');
    pztSvgElement.setAttribute('viewBox', '0 0 1600 1200');
    pztSvgElement.setAttribute('preserveAspectRatio', 'xMidYMid slice');
    pztSvgElement.innerHTML = `<g id="pzt-wrapper" style="transform-origin: center center; transform: rotate(${projectData.address.north_ang}deg) scale(0.832322);"></g>`;

    // @ts-ignore
    L.svgOverlay(pztSvgElement, bounds, {
      interactive: true,
      className: 'overlayVisible',
      scrollWheelZoom: false,
      // @ts-ignore
    }).addTo(container);
    setMap(container);
    // @ts-ignore
    L.gridLayer.googleMutant({ type: 'satellite' }).addTo(container);
  }, []);

  const movePZT = () => {
    const pztWrapper = document.getElementById('pzt-wrapper');
    const pzt = document.getElementById('PZT');
    if (pztWrapper && pzt) {
      pztWrapper.insertBefore(pzt, pztWrapper.firstChild);
    }
  };

  const handleAfterInjection = useCallback(() => {
    movePZT();
    addListeners();
  }, []);

  useEffect(() => {
    if (map) {
      // @ts-ignore
      // prettier-ignore
      map.on('zoomend', function() {
      // @ts-ignore
      map.panTo(position);
        
      });
    }
  });

  return (
    <>
      <div className={[classes.container, styles.container].join(' ')}>
        <div id="map" />
        <ReactSVG
          src={PZT}
          wrapper="svg"
          id="PZT"
          afterInjection={handleAfterInjection}
        />
        <div className={styles.logoWrapper}>
          <Logo />
        </div>
      </div>
      {!isMobile && (
        <Tooltip id="mapTooltip" isOpen={isTooltipOpen}>
          <div className={styles.tooltip}>
            <p>
              {t('name')}: <b>{building}</b>
            </p>
            <p>
              {t('offersAmount')}: <b>{flatsAmount}</b>
            </p>
            <p>
              {t('flatArea')}: <b>{flatsAreaRange}</b>m
              <sup className={styles.sup}>2</sup>
            </p>
            <p>
              {t('rooms')}: <b>{flatsRooms}</b>
            </p>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default MapView;
