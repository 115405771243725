import { useContext } from 'react';
import { createUseStyles } from 'react-jss';

import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import { CustomTheme } from 'types/Style';

import styles from './styles.module.scss';
import { Props } from './types';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  container: {
    backgroundColor: theme.primary,
  },
}));

const FloorSwitcher = ({
  onLowerFloorClick,
  onHigherFloorClick,
  currentFloor,
}: Props) => {
  const { t } = useContext(TranslationContext);
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });

  return (
    <div className={[classes.container, styles.container].join(' ')}>
      <button className={styles.button} onClick={onLowerFloorClick}>
        -
      </button>
      <span className={styles.label}>
        {currentFloor === 0
          ? t('groundFloor')
          : `${t('floor')} ${currentFloor}`}
      </span>
      <button className={styles.button} onClick={onHigherFloorClick}>
        +
      </button>
    </div>
  );
};

export default FloorSwitcher;
