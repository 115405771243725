import { useContext } from 'react';
import { createUseStyles } from 'react-jss';

import { StyleContext } from 'context/styleContext';
import { CustomTheme } from 'types/Style';

import styles from './styles.module.scss';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  skCubeGrid: {
    '& div': {
      backgroundColor: theme.primary,
    },
  },
}));

const Loader = () => {
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });

  return (
    <div className={[classes.skCubeGrid, styles.skCubeGrid].join(' ')}>
      <div className={[styles.skCube, styles.skCube1].join(' ')}></div>
      <div className={[styles.skCube, styles.skCube2].join(' ')}></div>
      <div className={[styles.skCube, styles.skCube3].join(' ')}></div>
      <div className={[styles.skCube, styles.skCube4].join(' ')}></div>
      <div className={[styles.skCube, styles.skCube5].join(' ')}></div>
      <div className={[styles.skCube, styles.skCube6].join(' ')}></div>
      <div className={[styles.skCube, styles.skCube7].join(' ')}></div>
      <div className={[styles.skCube, styles.skCube8].join(' ')}></div>
      <div className={[styles.skCube, styles.skCube9].join(' ')}></div>
    </div>
  );
};

export default Loader;
