import { useContext } from 'react';

import { TranslationContext } from 'context/translationContext';
import { scrollToSection } from 'utils/scrollToSection';

import styles from './styles.module.scss';

const Footer = () => {
  const { t } = useContext(TranslationContext);

  return (
    <section className={styles.container}>
      <div className={styles.menu}>
        <div>
          <p
            onClick={() => {
              scrollToSection('threeDSection');
            }}
          >
            {t('3dMockup')}+
          </p>
        </div>
        <div>
          <p
            onClick={() => {
              scrollToSection('tableSection');
            }}
          >
            {t('searchEngine')}+
          </p>
        </div>
        <div>
          <p
            onClick={() => {
              scrollToSection('gallerySection');
            }}
          >
            {t('gallery')}+
          </p>
        </div>
        <div>
          <p
            onClick={() => {
              scrollToSection('floorsSection');
            }}
          >
            {t('floors')}+
          </p>
        </div>
        <div>
          <p
            onClick={() => {
              scrollToSection('apartmentSection');
            }}
          >
            {t('catalog')}+
          </p>
        </div>
        <div>
          <p
            onClick={() => {
              scrollToSection('mapSection');
            }}
          >
            {t('maps')}+
          </p>
        </div>
        <div>
          <p
            onClick={() => {
              scrollToSection('vtripSection');
            }}
          >
            {t('vtrip')}+
          </p>
        </div>

        <div>
          <p
            onClick={() => {
              scrollToSection('cartSection');
            }}
          >
            {t('card')}+
          </p>
        </div>
        <div>
          <p
            onClick={() => {
              scrollToSection('visualisationSection');
            }}
          >
            {t('visualizations')}+
          </p>
        </div>
        <div>
          <p
            onClick={() => {
              scrollToSection('analyzerSection');
            }}
          >
            {t('investmentAnalyzer')}+
          </p>
        </div>
        <div>
          <p
            onClick={() => {
              scrollToSection('pagesSection');
            }}
          >
            {t('wwwWebsites')}+
          </p>
        </div>

        <div>
          <p
            onClick={() => {
              scrollToSection('graphicsSection');
            }}
          >
            {t('graphics')}+
          </p>
        </div>
        <div>
          <p
            onClick={() => {
              scrollToSection('scan3DSection');
            }}
          >
            {t('scan3D')}+
          </p>
        </div>
      </div>
      <div className={styles.copyrights}>{t('dataAdmin')}</div>
      <div className={styles.copyCompanyName}>{t('copyright')}</div>
    </section>
  );
};

export default Footer;
