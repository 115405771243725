import { useContext } from 'react';
import { createUseStyles } from 'react-jss';

import DetailElement from 'components/DetailElement';
import { COMPARTMENT_NAMES, WORLD_DIRECTION_NAMES } from 'constants/options';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import { CustomTheme } from 'types/Style';
// import { calculateInsolation } from 'utils/calculateInsolation';

import styles from './styles.module.scss';
import { Props } from './types';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  roomWrapper: {
    borderBottom: `1px solid ${theme.primary}`,
  },
}));

const RoomDetails = ({ room }: Props) => {
  const { t } = useContext(TranslationContext);
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor },
  });

  const compNames: { [k: string]: string } = COMPARTMENT_NAMES(t);
  const roomDirectons: { [k: string]: string } = WORLD_DIRECTION_NAMES(t);

  const buildRoomDirectionElement = (direction: string) => {
    const directions = direction.split(',');

    return (
      <>
        {directions.map(direction => (
          <span>{roomDirectons[direction]}</span>
        ))}
      </>
    );
  };

  return (
    <div className={[classes.roomWrapper, styles.roomWrapper].join(' ')}>
      <div className={styles.roomTitle}>{compNames[room.name]}</div>
      <div className={styles.detailsWrapper}>
        <div className={styles.leftCol}>
          <DetailElement
            caption={t('area')}
            dynamicValue={() => (
              <span>
                {room.area}m<sup>2</sup>
              </span>
            )}
          />
          {/* {room.insolation ? (
            <DetailElement
              caption={t('insolation')}
              dynamicValue={() => (
                <span>{calculateInsolation(room.insolation || 0)}</span>
              )}
            />
          ) : null} */}
        </div>
        <div className={styles.rightCol}>
          {/* <DetailElement
            caption={t('floor')}
            value={room.ref_tier.toString()}
          /> */}
          {room.exposition ? (
            <DetailElement
              caption={t('worldDirections')}
              dynamicValue={() =>
                buildRoomDirectionElement(room.exposition || '')
              }
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RoomDetails;
