import styles from './styles.module.scss';
import { Props } from './types';

const FilterModalItem = ({ label, children }: Props) => {
  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      {children}
    </div>
  );
};

export default FilterModalItem;
