import CarouselDevs from 'components/CarouselDevs';

import img1 from '../../assets/images/3d-1.jpg';
import img2 from '../../assets/images/3d-2.png';
import img3 from '../../assets/images/3d-3.png';

const LogosGallery = () => {
  function importAll(r: any) {
    return r.keys().map(r);
  }

  const deweloperzy = importAll(
    require.context(
      '../../assets/images/deweloperzy/',
      false,
      /\.(png|jpe?g|svg)$/,
    ),
  );

  const architekci = importAll(
    require.context(
      '../../assets/images/architekci/',
      false,
      /\.(png|jpe?g|svg)$/,
    ),
  );

  return (
    <div>
      <CarouselDevs slides={5} imagesUrls={architekci} />
      <CarouselDevs slides={8} imagesUrls={deweloperzy} />
    </div>
  );
};

export default LogosGallery;
