import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  useReactTable,
  SortingState,
  PaginationState,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { useContext, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';

import ApartamentsTiles from 'components/ApartamentsTiles';
import Filters from 'components/Filters';
import { AVAILABLE } from 'constants/apartmentStatus';
import { ContactModalContext } from 'context/contactModalContext';
import { NavigationContext } from 'context/navigationContext';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import {
  balconyOrTerraceFilter,
  directionsFilter,
  flatPlacementFilter,
  floorFilter,
  kitchenFilter,
  priceFilter,
  pricePerMeterFilter,
  statusFilter,
} from 'tableFilters';
import { CustomTheme, ListType } from 'types/Style';
import { scrollToSection } from 'utils/scrollToSection';

import styles from './styles.module.scss';
import { Props } from './types';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  container: {
    backgroundColor: theme.primary,
  },
  tableWrapper: {
    '& tbody tr': {
      backgroundColor: `${theme.primary} !important`,
    },
  },
  tableFooter: {
    backgroundColor: `${theme.primary} !important`,
    '& button': {
      backgroundColor: `${theme.primary} !important`,
    },
  },
}));

const ApartamentsTable = ({ data }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [currentView, setCurrentView] = useState<ListType>('tabela');
  const { openModal } = useContext(ContactModalContext);
  const { t } = useContext(TranslationContext);
  const { listType } = useContext(StyleContext);
  const { setSelectedAptId } = useContext(NavigationContext);
  const { primaryColor } = useContext(StyleContext);
  const classes = useStyles({ theme: { primary: primaryColor } });

  useEffect(() => {
    if (listType) {
      setCurrentView(listType);
    }
  }, [listType]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageIndex, pageSize],
  );

  const areTilesSelected = useMemo(
    () => currentView === 'kafelki',
    [currentView],
  );

  const maxTier: number = useMemo(() => {
    let maximumTier = 0;

    const aprtsCompartments = data.map(d => d.compartments);

    aprtsCompartments.forEach(compartments => {
      const compartmentsTiers = compartments.map(
        compartment => compartment.ref_tier,
      );
      const max = Math.max(...compartmentsTiers);
      if (max > maximumTier) {
        maximumTier = max;
      }
    });

    return maximumTier;
  }, []);

  const columnHelper = createColumnHelper<any>();

  const handleBtnClick = (row: any) => {
    if (row.status === AVAILABLE) {
      openModal(`${t('interestedIn')} ${row.number}, ${t('presentOffer')}`);
    }
  };

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      columnHelper.accessor('number', {
        header: () => <span>{t('no')}</span>,
      }),
      columnHelper.accessor('area', {
        header: () => <span>{t('areaShorthand')}</span>,
        cell: table => (
          <span>
            {table.row.original.area}m<sup>2</sup>
          </span>
        ),
      }),
      columnHelper.accessor('rooms', {
        header: () => <span>{t('rooms')}</span>,
      }),
      columnHelper.accessor('status', {
        header: () => <span>{t('status')}</span>,
        cell: table => {
          const getButton = (status: string) => (
            <button
              className={[
                styles.btn,
                status !== AVAILABLE && styles.unavailableBtn,
              ].join(' ')}
              onClick={() => handleBtnClick(table.row.original)}
            >
              {status === AVAILABLE ? t('available') : t('sold')}
            </button>
          );

          return getButton(table.row.original.status || t('askForOffer'));
        },
        filterFn: statusFilter,
      }),
      columnHelper.accessor('balconyOrTerrace', {
        filterFn: balconyOrTerraceFilter,
      }),
      // TODO: price filters
      columnHelper.accessor('price', {
        filterFn: priceFilter,
      }),
      columnHelper.accessor('pricePerMeter', {
        filterFn: pricePerMeterFilter,
      }),
      columnHelper.accessor('floor', {
        filterFn: floorFilter,
      }),
      columnHelper.accessor('kitchen', {
        filterFn: kitchenFilter,
      }),
      columnHelper.accessor('flatPlacement', {
        filterFn: (row, colId, filterValue) =>
          flatPlacementFilter(row, colId, filterValue, maxTier),
      }),
      columnHelper.accessor('directions', {
        filterFn: directionsFilter,
      }),
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
      columnVisibility: {
        balconyOrTerrace: false,
        price: false,
        pricePerMeter: false,
        floor: false,
        kitchen: false,
        flatPlacement: false,
        directions: false,
      },
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const getSortIcon = (sortState: string | boolean) => {
    if (typeof sortState === 'string') {
      return (
        <span className={styles.sortIcon}>
          {sortState === 'asc' ? '▲' : '▼'}
        </span>
      );
    }

    return null;
  };

  const handleRowClick = (row: any, cell: any) => {
    if (cell.column.id !== 'status' && row.original.status === AVAILABLE) {
      setSelectedAptId(cell.row.original.id);
      scrollToSection('apartmentSection');
    }
  };

  const handleViewChange = (type: ListType) => {
    setCurrentView(type);
  };

  return (
    <div className={[classes.container, styles.container].join(' ')}>
      <Filters
        table={table}
        onViewChange={handleViewChange}
        currentView={currentView}
      />
      <div
        className={[
          classes.tableWrapper,
          styles.tableWrapper,
          styles.tilesTableWrapper,
        ].join(' ')}
      >
        <>
          {areTilesSelected ? (
            <ApartamentsTiles data={data} />
          ) : (
            <table className={styles.wrapper} cellSpacing="0" cellPadding="0">
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div
                            className={[
                              styles.headerWrapper,
                              typeof header.column.getIsSorted() === 'string'
                                ? styles.highlighted
                                : '',
                            ].join(' ')}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {getSortIcon(header.column.getIsSorted())}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr
                    key={row.id}
                    className={[
                      styles.row,
                      row.original.status !== AVAILABLE &&
                        styles.unavailableRow,
                    ].join(' ')}
                  >
                    {row.getVisibleCells().map(cell => (
                      <td
                        key={cell.id}
                        onClick={() => handleRowClick(row, cell)}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
        <div className={styles.tableFooter}>
          {/* {pageSize > 20 && (
            <button
              onClick={() => {
                setTimeout(() => {
                  const height = document.getElementById('root')?.scrollHeight;
                  window.parent.postMessage(['setHeight', height], '*');
                }, 100);

                setPagination(prevState => ({
                  ...prevState,
                  pageSize: prevState.pageSize - 20,
                }));
              }}
            >
              Pokaż mniej
            </button>
          )} */}
          {!areTilesSelected && table.getCanNextPage() && (
            <div
              className={[classes.tableFooter, styles.tableFooter].join(' ')}
            >
              <button
                onClick={() => {
                  if (!table.getCanNextPage()) return;
                  setTimeout(() => {
                    const height =
                      document.getElementById('root')?.scrollHeight;
                    window.parent.postMessage(['setHeight', height], '*');
                  }, 100);

                  setPagination(prevState => ({
                    ...prevState,
                    pageSize: prevState.pageSize + 15,
                  }));
                }}
              >
                {t('showMore')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApartamentsTable;
