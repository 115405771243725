import React, { createContext } from 'react';
import { useIntl } from 'react-intl';

export interface ITranslation {
  t: (id: string) => string;
}

interface TranslationProps {
  children: React.ReactNode;
}

export const TranslationContext = createContext<ITranslation>({
  t: id => id,
});

export const TranslationProvider = ({ children }: TranslationProps) => {
  const { formatMessage } = useIntl();
  const t = (id: string) => formatMessage({ id });

  return (
    <TranslationContext.Provider value={{ t }}>
      {children}
    </TranslationContext.Provider>
  );
};
