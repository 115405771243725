import { XIcon } from '@heroicons/react/outline';
import { useContext, useMemo } from 'react';
// TODO: uncomment when exposition filter will work
// import CheckboxSelect from 'components/CheckboxSelect';
import { createUseStyles } from 'react-jss';

import DropdownModalItem from 'components/DropdownModalItem';
import FilterModalItem from 'components/FilterModalItem';
import FlatPlacementSelect from 'components/FlatPlacementSelect';
import InputRange from 'components/InputRange';
import RadioSelect from 'components/RadioSelect';
import {
  // PRICE_OPTIONS,
  // PRICE_PER_METER_OPTIONS,
  EXTERNAL_AREA_OPTIONS,
  // KITCHEN_OPTIONS,
  // TODO: uncomment when exposition filter will work
  // DIRECTIONS,
  FLAT_PLACEMENT_OPTIONS,
} from 'constants/options';
import { DataContext } from 'context/dataContext';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import { CustomTheme } from 'types/Style';

import styles from './styles.module.scss';
import { Props } from './types';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  btn: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primaryDark,
    },
  },
  closeBtn: {
    color: theme.primary,
  },
}));

const FiltersModal = ({
  handleClose,
  values,
  onChange,
  onSetFieldValue,
  disabledFilters,
}: Props) => {
  const { t } = useContext(TranslationContext);
  const { areaOptions, floorOptions, roomsOptions } = useContext(DataContext);
  const { primaryColor, primaryColorDark } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor, primaryDark: primaryColorDark },
  });

  const parsedRooms = useMemo(() => roomsOptions.map(String), [roomsOptions]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.closeBtnWrapper} onClick={handleClose}>
          <XIcon className={[classes.closeBtn, styles.closeBtn].join(' ')} />
        </div>
        <div className={styles.filtersWrapper}>
          <div className={styles.col}>
            {/* <FilterModalItem label={`${t('price')} (${t('pln')}):`}>
              <InputRange
                minValue={values.priceMin}
                maxValue={values.priceMax}
                minValueKey="priceMin"
                maxValueKey="priceMax"
                minValueOptions={PRICE_OPTIONS}
                maxValueOptions={PRICE_OPTIONS}
                onChange={onChange}
                minPlaceholder="Od"
                maxPlaceholder="Do"
                disabled={disabledFilters['price']}
              />
            </FilterModalItem>
            <FilterModalItem label={`${t('price')} (m²):`}>
              <InputRange
                minValue={values.pricePerMeterMin}
                maxValue={values.pricePerMeterMax}
                minValueKey="pricePerMeterMin"
                maxValueKey="pricePerMeterMax"
                minValueOptions={PRICE_PER_METER_OPTIONS}
                maxValueOptions={PRICE_PER_METER_OPTIONS}
                onChange={onChange}
                minPlaceholder={t('from')}
                maxPlaceholder={t('to')}
                disabled={disabledFilters['pricePerMeter']}
              />
            </FilterModalItem> */}
            <FilterModalItem label={`${t('rooms')}:`}>
              <InputRange
                minValue={values.roomMin}
                maxValue={values.roomMax}
                minValueKey="roomMin"
                maxValueKey="roomMax"
                minValueOptions={parsedRooms}
                maxValueOptions={parsedRooms}
                onChange={onChange}
                minPlaceholder={t('from')}
                maxPlaceholder={t('to')}
              />
            </FilterModalItem>
            <FilterModalItem label={`${t('area')} (m²):`}>
              <InputRange
                minValue={values.areaMin}
                maxValue={values.areaMax}
                minValueKey="areaMin"
                maxValueKey="areaMax"
                minValueOptions={areaOptions}
                maxValueOptions={areaOptions}
                onChange={onChange}
                minPlaceholder={t('from')}
                maxPlaceholder={t('to')}
                disabled={disabledFilters['area']}
              />
            </FilterModalItem>
          </div>
          <div className={styles.col}>
            <FilterModalItem label={`${t('floor')}:`}>
              <InputRange
                minValue={values.floorMin}
                maxValue={values.floorMax}
                minValueKey="floorMin"
                maxValueKey="floorMax"
                minValueOptions={floorOptions}
                maxValueOptions={floorOptions}
                onChange={onChange}
                minPlaceholder={t('from')}
                maxPlaceholder={t('to')}
              />
            </FilterModalItem>
          </div>
        </div>
        <h2 className={styles.label}>{t('flat')}</h2>
        <div className={styles.filtersWrapper}>
          <div className={styles.col}>
            <DropdownModalItem label={t('flatPlacement')}>
              <FlatPlacementSelect
                name="flatPlacement"
                values={values.flatPlacement}
                options={FLAT_PLACEMENT_OPTIONS(t)}
                onChange={onChange}
              />
            </DropdownModalItem>
          </div>
          <div className={styles.col}>
            <DropdownModalItem label={t('externalArea')}>
              <RadioSelect
                name="externalArea"
                value={values.externalArea}
                options={EXTERNAL_AREA_OPTIONS(t)}
                onSetFieldValue={onSetFieldValue}
              />
            </DropdownModalItem>
            {/* <DropdownModalItem label={t('kitchen')}>
              <RadioSelect
                name="kitchenType"
                value={values.kitchenType}
                options={KITCHEN_OPTIONS(t)}
                onSetFieldValue={onSetFieldValue}
              />
            </DropdownModalItem> */}
            {/* exposition filter temporary disabled */}
            {/* <DropdownModalItem label={t('windowsPlacement')}>
              <CheckboxSelect
                name="directions"
                values={values.directions}
                options={DIRECTIONS(t)}
                onChange={onChange}
              />
            </DropdownModalItem> */}
          </div>
        </div>
        <button
          onClick={handleClose}
          className={[classes.btn, styles.btn].join(' ')}
        >
          {t('show')}
        </button>
      </div>
      <div className={styles.overlay} onClick={handleClose} />
    </div>
  );
};

export default FiltersModal;
