import { XIcon } from '@heroicons/react/outline';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { createUseStyles } from 'react-jss';

import { sendEmail } from 'api/sendEmail.api';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import { ContactModalContext } from 'context/contactModalContext';
import { StyleContext } from 'context/styleContext';
import { TranslationContext } from 'context/translationContext';
import { CustomTheme } from 'types/Style';
import { ContactSchema } from 'utils/validation';

import styles from './styles.module.scss';
import { Props } from './types';

const useStyles = createUseStyles((theme: CustomTheme) => ({
  closeBtn: {
    color: theme.primary,
  },
  checkedTermsCheckbox: {
    backgroundColor: theme.primary,
  },
  termsError: {
    color: theme.primary,
  },
  btn: {
    backgroundColor: theme.primary,
    '&:hover': {
      color: theme.primary,
      backgroundColor: theme.primaryDark,
    },
  },
  callBtn: {
    '&:hover': {
      color: theme.primary,
    },
  },
  sendMessage: {
    color: theme.primary,
  },
}));

const ContactModal = ({ message }: Props) => {
  const {
    closeModal,
    contactPhone,
    mailUrl,
    mailEndpoint,
    mailRecipient,
    privacyPolicyLink,
  } = useContext(ContactModalContext);
  const { t } = useContext(TranslationContext);
  const [sendMessage, setSendMessage] = useState<string>('');
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const { primaryColor, primaryColorDark } = useContext(StyleContext);
  const classes = useStyles({
    theme: { primary: primaryColor, primaryDark: primaryColorDark },
  });

  const handleSubmit = () => {
    if (submitDisabled) return;

    const { name, email, phone, message } = formik.values;
    const queryParams = new URLSearchParams(window.location.search);

    const utmCampaign = queryParams.get('utm_campaign') || '';
    const utmSource = queryParams.get('utm_source') || '';
    const utmMedium = queryParams.get('utm_medium') || '';
    const utmTerm = queryParams.get('utm_term') || '';
    const utmContent = queryParams.get('utm_content') || '';

    sendEmail(
      name,
      email,
      phone,
      message,
      utmCampaign,
      utmSource,
      utmMedium,
      utmTerm,
      utmContent,
      mailUrl,
      mailEndpoint,
      mailRecipient,
    )
      .then(() => {
        setSendMessage(t('sendMessageSuccess'));
        setSubmitDisabled(true);
        formik.resetForm();
      })
      .catch(() => setSendMessage(t('sendMessageError')));
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      message,
      termsAccepted: false,
      marketingTermsAccepted: false,
    },
    onSubmit: () => handleSubmit(),
    validationSchema: ContactSchema(t),
  });

  const changeAcceptTermsValue = () => {
    formik.setFieldValue('termsAccepted', !formik.values.termsAccepted);
  };

  const changeAcceptMarketingTermsValue = () => {
    formik.setFieldValue(
      'marketingTermsAccepted',
      !formik.values.marketingTermsAccepted,
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.closeBtnWrapper} onClick={closeModal}>
          <XIcon className={[classes.closeBtn, styles.closeBtn].join(' ')} />
        </div>
        <h2 className={styles.header}>{t('askForOffer')}</h2>
        <div className={styles.firstRow}>
          <Input
            label={t('contactName')}
            value={formik.values.name}
            placeholder={isMobile ? t('contactName') : t('yourName')}
            onChange={formik.handleChange('name')}
            error={formik.touched.name ? formik.errors.name : ''}
          />
          <Input
            label={t('mail')}
            value={formik.values.email}
            placeholder={isMobile ? t('eMailAddress') : t('yourEMailAddress')}
            onChange={formik.handleChange('email')}
            error={formik.touched.email ? formik.errors.email : ''}
          />
          <Input
            label={t('phone')}
            value={formik.values.phone}
            placeholder={isMobile ? t('phoneNumber') : t('yourPhoneNumber')}
            onChange={formik.handleChange('phone')}
            error={formik.touched.phone ? formik.errors.phone : ''}
          />
        </div>
        <TextArea
          label={t('message')}
          value={formik.values.message}
          placeholder={t('yourMessage')}
          onChange={formik.handleChange('message')}
          error={formik.touched.message ? formik.errors.message : ''}
        />
        <div className={styles.termsWrapper}>
          <div
            className={styles.termsCheckboxContainer}
            onClick={changeAcceptTermsValue}
          >
            {formik.values.termsAccepted ? (
              <div
                className={[
                  classes.checkedTermsCheckbox,
                  styles.checkedTermsCheckbox,
                ].join(' ')}
              />
            ) : null}
          </div>
          {!!privacyPolicyLink ? (
            <a className={styles.link} href={privacyPolicyLink} target="_blank">
              <span className={styles.label}>{t('acceptTerms')}</span>
            </a>
          ) : (
            <span className={styles.label}>{t('acceptTerms')}</span>
          )}
        </div>
        {formik.touched.termsAccepted && (
          <span className={[classes.termsError, styles.termsError].join(' ')}>
            {formik.errors.termsAccepted}
          </span>
        )}
        <div className={styles.termsWrapper}>
          <div
            className={styles.termsCheckboxContainer}
            onClick={changeAcceptMarketingTermsValue}
          >
            {formik.values.marketingTermsAccepted ? (
              <div className={styles.checkedTermsCheckbox} />
            ) : null}
          </div>
          <span className={styles.label}>{t('acceptMarketingTerms')}</span>
        </div>
        {formik.touched.marketingTermsAccepted && (
          <span className={styles.termsError}>
            {formik.errors.marketingTermsAccepted}
          </span>
        )}
        <button
          className={[classes.btn, styles.btn].join(' ')}
          // @ts-ignore
          onClick={formik.handleSubmit}
        >
          {t('askForOffer')}
        </button>
        <p className={styles.orCall}>{t('orCall')}</p>
        <a
          href={`tel:${contactPhone}`}
          className={[classes.callBtn, styles.callBtn].join(' ')}
        >
          {contactPhone}
        </a>
        {sendMessage && (
          <span className={[classes.sendMessage, styles.sendMessage].join(' ')}>
            {sendMessage}
          </span>
        )}
      </div>
      <div className={styles.overlay} onClick={closeModal} />
    </div>
  );
};

export default ContactModal;
