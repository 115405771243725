import { useFormik } from 'formik';
import { createContext, ReactNode } from 'react';

import { FilterValues } from 'types/FilterValues';

const initialValues: FilterValues = {
  priceMin: '',
  priceMax: '',
  roomMin: '',
  roomMax: '',
  areaMin: '',
  areaMax: '',
  pricePerMeterMin: '',
  pricePerMeterMax: '',
  floorMin: '',
  floorMax: '',
  dueDateMin: '',
  dueDateMax: '',
  floorAmountMin: '',
  floorAmountMax: '',
  externalArea: '',
  flatPlacement: [],
  flatLayout: [],
  kitchenType: '',
  bathroomInfo: [],
  directions: [],
  flatAmenities: [],
  estateAmenities: [],
  buildingAmenities: [],
  garageType: [],
  garageCharging: false,
  areaAmenities: [],
  specialOffers: [],
};

export interface ApartmentsFilterContext {
  setFilterValue: (k: string, v: any) => void;
  filterValues: FilterValues;
  handleFilterChange: (e: any) => void;
  resetFilters: () => void;
}

interface ApartmentsFilterContextProps {
  children: ReactNode;
}

export const ApartmentsFilterContext = createContext<ApartmentsFilterContext>({
  setFilterValue: () => {},
  filterValues: initialValues,
  handleFilterChange: () => {},
  resetFilters: () => {},
});

export const ApartmentsFilterProvider = ({
  children,
}: ApartmentsFilterContextProps) => {
  const handleSubmit = () => {
    // TODO: Handle filter
  };

  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      handleSubmit();
    },
  });

  const {
    setFieldValue: setFilterValue,
    values: filterValues,
    handleChange: handleFilterChange,
    resetForm: resetFilters,
  } = formik;

  return (
    <ApartmentsFilterContext.Provider
      value={{
        setFilterValue,
        filterValues,
        handleFilterChange,
        resetFilters,
      }}
    >
      {children}
    </ApartmentsFilterContext.Provider>
  );
};
