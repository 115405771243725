import Carousel from 'components/Carousel';

import img2 from '../../assets/images/karty-1.jpg';
import img11 from '../../assets/images/karty-10.jpg';
import img12 from '../../assets/images/karty-11.jpg';
import img13 from '../../assets/images/karty-12.jpg';
import img14 from '../../assets/images/karty-13.jpg';
import img15 from '../../assets/images/karty-14.jpg';
import img16 from '../../assets/images/karty-15.jpg';
import img17 from '../../assets/images/karty-16.jpg';
import img18 from '../../assets/images/karty-17.jpg';
import img19 from '../../assets/images/karty-18.jpg';
import img20 from '../../assets/images/karty-19.jpg';
import img3 from '../../assets/images/karty-2.jpg';
import img21 from '../../assets/images/karty-20.jpg';
import img22 from '../../assets/images/karty-21.jpg';
import img4 from '../../assets/images/karty-3.jpg';
import img5 from '../../assets/images/karty-4.jpg';
import img6 from '../../assets/images/karty-5.jpg';
import img7 from '../../assets/images/karty-6.jpg';
import img8 from '../../assets/images/karty-7.jpg';
import img9 from '../../assets/images/karty-8.jpg';
import img10 from '../../assets/images/karty-9.jpg';

const CardGallery = () => {
  const cardSectionImages = [
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
  ];

  return (
    <div>
      <Carousel imagesUrls={cardSectionImages} />
    </div>
  );
};

export default CardGallery;
