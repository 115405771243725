import { Row } from '@tanstack/react-table';

export const balconyOrTerraceFilter = (
  row: Row<any>,
  columnId: string,
  filterValue: any,
): boolean => {
  if (filterValue === 'balkon') return !!row.original.balconys;
  if (filterValue === 'taras') return !!row.original.terraces;

  return false;
};
