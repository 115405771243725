import { useContext, useState } from 'react';

import ShowMore from 'components/ShowMore';
import { TranslationContext } from 'context/translationContext';

const Scan3DDescription = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useContext(TranslationContext);

  const toggleOpen = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div>
      <span>{t('scan3DClaim')}</span>
      <br />
      <br />
      <ul>
        <li>{t('scan3DAdvantage1')}</li>
        <li>{t('scan3DAdvantage2')}</li>
        <li>{t('scan3DAdvantage3')}</li>
        <li>{t('scan3DAdvantage4')}</li>
        <li>{t('scan3DAdvantage5')}</li>
      </ul>
      {isOpen && (
        <>
          <br />
          <p>{t('scan3DDescription1')}</p>
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="https://my.matterport.com/show/?m=hoa5Ubo1gSC&play=1" frameborder="0" allowfullscreen=""></iframe>',
            }}
          />
        </>
      )}
      <ShowMore isOpen={isOpen} handleClick={toggleOpen} />
    </div>
  );
};

export default Scan3DDescription;
